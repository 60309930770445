import { Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Error404 from '../../components/Error404';
import request from '../../services/request';

function LegalTerms() {
  const { t } = useTranslation();
  const [{ isLoading, data }, setState] = useState({ isLoading: true, data: null });

  useEffect(() => {
    request('/locale/tos')
      .then((s) => setState({ isLoading: false, data: s }))
      .catch(() => setState({ isLoading: false, data: null }));
  }, []);

  if (isLoading) return <Skeleton />;
  if (!data) return <Error404 />;

  return (
    <>
      <Title>{t('FRONT_SIDEBAR_LEGAL_TERMS')}</Title>
      <embed
        style={{ width: '100%', height: 'calc(100vh - 200px)' }}
        src={`data:${data.mime};base64,${data.file}`}
      />
    </>
  );
}

export default LegalTerms;
