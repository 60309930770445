import { Button, Layout, Menu } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from './Footer';
import Header from './Header';

function LayoutPublic({ children }) {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Content className="content">{children}</Content>
      <Footer />
    </Layout>
  );
}

LayoutPublic.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LayoutPublic;
