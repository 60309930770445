import React from 'react';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';
import WorkflowUser from '../../components/Workflow/WorkflowUser';

const { Title, Paragraph, Text, Link } = Typography;

function Dashboard() {
  return (
    <>
      <div className="dashboard">
        <Typography className="dashboard__content">
          <Title style={{ fontWeight: 'normal' }}>
            <Trans i18nKey="FRONT_HOME_TITLE" />
          </Title>
          <Paragraph>
            <Trans i18nKey="FRONT_HOME_WELCOME" />
          </Paragraph>
          <Paragraph>
            <Trans i18nKey="FRONT_HOME_DESCRIPTION" />
          </Paragraph>
          <Paragraph>
            <Trans i18nKey="FRONT_DASHBOARD_CREATION" />
          </Paragraph>
          <Paragraph>
            <Trans i18nKey="FRONT_DASHBOARD_LIST">
              Voici les étapes :
              <ol className="list__numbered">
                <li> Je finis mon inscription en complétant mon profil</li>
                <li> Je crée mon dossier médical</li>
                <li> Je remplis mon questionnaire de santé</li>
                <li> J’ajoute mes documents médicaux</li>
                <li> J’attends d’être contacté(e) par le Bureau International Elsan</li>
              </ol>
            </Trans>
          </Paragraph>
          <Paragraph>
            <Trans i18nKey="FRONT_HOME_TEAM" />

            <a href="mailto:international@elsan.care">international@elsan.care</a>
          </Paragraph>
        </Typography>
      </div>
    </>
  );
}

export default Dashboard;
