import { ASK_DOCUMENTS_IS_FETCHING, SET_ASK_DOCUMENTS } from './actions';

const askDocuments = (state = [null, true], action) => {
  switch (action.type) {
    case SET_ASK_DOCUMENTS:
      return [action.askDocuments, false];
    case ASK_DOCUMENTS_IS_FETCHING:
      return [null, true];
    default:
      return state;
  }
};

export default askDocuments;
