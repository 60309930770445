/* eslint-disable react/prop-types */
import { Alert, Form } from 'antd';
import React from 'react';

function FormErrors({ err }) {
  if (!err) return null;
  const { errors, message } = err;

  if (errors) {
    const arr = Object.values(errors);
    return (
      <Form.Item>
        <Alert message={arr[0]} type="error" showIcon />
      </Form.Item>
    );
  }

  return (
    <Form.Item>
      <Alert message={message} type="error" showIcon />
    </Form.Item>
  );
}

export default FormErrors;
