import { Menu } from 'antd';
import { Footer as ANTDFooter } from 'antd/lib/layout/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function Footer() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <ANTDFooter className="footer">
      <Menu theme="dark" mode="horizontal" style={{ display: 'flex', justifyContent: 'center' }}>
        <Menu.Item key="/contact" onClick={() => history.push('/contact')}>
          Contact
        </Menu.Item>
        <Menu.Item key="/policy" onClick={() => history.push('/policy')}>
          {t('FRONT_SIDEBAR_PRIVACY_POLICY')}
        </Menu.Item>
        <Menu.Item key="/cgu" onClick={() => history.push('/cgu')}>
          {t('FRONT_SIDEBAR_LEGAL_TERMS')}
        </Menu.Item>
        <Menu.Item key="/pds" onClick={() => history.push('/pds')}>
          Plan du site
        </Menu.Item>
      </Menu>
    </ANTDFooter>
  );
}

export default Footer;
