import request from '../../services/request';

export const SET_STEP = 'SET_STEP';
export const STEP_IS_FETCHING = 'STEP_IS_FETCHING';
export const STEP_STOP_FETCHING = 'STEP_STOP_FETCHING';

const setStep = (step) => ({ type: SET_STEP, step });
const isFetching = () => ({ type: STEP_IS_FETCHING });
const stopFetching = () => ({ type: STEP_STOP_FETCHING });

export const fetchStep = (reference) => async (dispatch) => {
  dispatch(isFetching());
  try {
    const step = await request(`/medicalfiles/${reference}/step`, 'GET');
    dispatch(setStep(step));
  } catch {
    dispatch(dispatch(stopFetching()));
  }
};

export const nextStep = (reference) => async (dispatch) => {
  dispatch(isFetching());
  try {
    await request(`/medicalfiles/${reference}/step/validate`, 'POST');
    dispatch(fetchStep(reference));
  } catch {
    dispatch(stopFetching());
  }
};
