import { Radio, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function SelectCivility(props) {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('FRONT_USER_CIVILITY')}
      name="gender"
      rules={[
        {
          required: true,
          message: `${t('FRONT_USER_CIVILITY')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
        },
      ]}
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
    >
      <Radio.Group>
        <Radio value={1}>{t('FRONT_USER_MRS')}</Radio>
        <Radio value={0}>{t('FRONT_USER_MR')}</Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default SelectCivility;
