import request from '../../services/request';

export const SET_ROLES = 'SET_ROLES';
export const ROLES_IS_FETCHING = 'ROLES_IS_FETCHING';

const setRoles = (roles) => ({ type: SET_ROLES, roles });
const isFetching = () => ({ type: ROLES_IS_FETCHING });

export const fetchRoles = () => async (dispatch) => {
  dispatch(isFetching());
  request('/role/list', 'GET')
    .then(async ({ roles }) => dispatch(setRoles(roles)))
    .catch(() => dispatch(setRoles([])));
};
