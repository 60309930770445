import { combineReducers } from 'redux';
import user from './user/reducer';
import metadata from './metadata/reducer';
import documents from './documents/reducer';
import medicalFile from './medicalFile/reducer';
import roles from './roles/reducer';
import step from './step/reducer';
import permissions from './permissions/reducer';
import askDocuments from './askDocuments/reducer';
import workflowUser from './workflowUser/reducer';
import medicals from './medicals/reducer';

const rootReducer = combineReducers({
  user,
  metadata,
  documents,
  medicalFile,
  roles,
  step,
  permissions,
  askDocuments,
  medicals,
  workflowUser,
});

export default rootReducer;
