import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, message } from 'antd';
import Loading from '../../components/layouts/Loading';
import request from '../../services/request';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function EmailCheck() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const query = useQuery();
  const [currentState, setState] = useState({ isFetching: true, err: null, success: false });

  const checkEmail = useCallback(async () => {
    const hash = query.get('hash');
    const id = query.get('id');
    const signature = query.get('signature');
    const expires = query.get('expires');
    try {
      await request(`/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`);
      setState({ isFetching: false, err: null, success: true });
      setTimeout(() => {
        history.push('/dashboard');
      }, 3000);
    } catch (err) {
      setState({ isFetching: false, err, success: false });
      message.error(t('FRONT_NOTIFICATION_SEND_FAILED'));
    }
  }, [history, query, t]);

  useEffect(() => {
    checkEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentState.isFetching) return <Loading />;
  if (currentState.success) {
    return (
      <Alert
        style={{ marginBottom: 24 }}
        message="Important"
        description={t('FRONT_EMAIL_VERIFY')}
        type="success"
        showIcon
      />
    );
  }
  return null;
}

export default EmailCheck;
