import {
  CarOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FileUnknownOutlined,
  MailOutlined,
  OrderedListOutlined,
  ProjectOutlined,
  SmileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRoles } from '../../../redux/user/selectors';
import { isAdmin, isExpertDoctor, isPatient } from '../../../services/rights';
import useStepActive from '../../../hooks/useT4TStepActive';
import { useMedicalFile } from '../../../redux/medicalFile/selectors';
import useHasPermission from '../../../hooks/useHasPermission';

const { TabPane } = Tabs;

function T4TTabs() {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [medicalFile] = useMedicalFile();
  const roles = useRoles();
  const { isStep, beforeStep } = useStepActive();
  const hasPermission = useHasPermission();

  return (
    <Tabs activeKey={location.pathname} onChange={(key) => history.push(key)}>
      <TabPane
        tab={
          <>
            <FileProtectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_PATIENT')}
          </>
        }
        key={`/medicals/${params.reference}/patient`}
      />
      <TabPane
        tab={
          <>
            <FileProtectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_FILE')}
          </>
        }
        key={`/medicals/${params.reference}`}
      />
      <TabPane
        tab={
          <>
            <UserOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_HEALTH_FORM')}
          </>
        }
        key={`/medicals/${params.reference}/health`}
      />
      <TabPane
        tab={
          <>
            <FilePdfOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_DOCUMENTS')}
          </>
        }
        key={`/medicals/${params.reference}/documents`}
      />
      <TabPane
        tab={
          <>
            <ProjectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_MANAGEMENT')}
          </>
        }
        key={`/medicals/${params.reference}/management`}
      />
      {medicalFile.assistance !== 0 && (
        <TabPane
          disabled={
            beforeStep('patient.stepfilltravelform') ||
            (isAdmin(roles) && beforeStep('system.stepcommercialproposition'))
          }
          tab={
            <>
              <CarOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_TRAVEL')}
            </>
          }
          key={`/medicals/${params.reference}/travel`}
        />
      )}
      {hasPermission('view:doctoranswers') && (
        <TabPane
          disabled={beforeStep('doctor.stepvalidatemedicalfile')}
          tab={
            <>
              <CarOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_DOCTOR')}
            </>
          }
          key={`/medicals/${params.reference}/doctor`}
        />
      )}
      <TabPane
        tab={
          <>
            <MailOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_CONTACT')}
          </>
        }
        key={`/medicals/${params.reference}/contact`}
      />
      {hasPermission('view satisfactionanswers') && (
        <TabPane
          disabled={
            (!isPatient(roles) && !isStep('system.fileclosed')) ||
            (isPatient(roles) && beforeStep('patient.stepfillsatisfactionform'))
          }
          tab={
            <>
              <SmileOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_SATISFACTION_FORM')}
            </>
          }
          key={`/medicals/${params.reference}/satisfaction`}
        />
      )}
    </Tabs>
  );
}

export default T4TTabs;
