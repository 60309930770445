import { Button, Form, Image, message, Typography, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import FormErrors from '../../components/form/FormErrors';
import Profile from '../../components/Profile';
import { useProfile, useRoles, useUser } from '../../redux/user/selectors';
import { setProfile } from '../../redux/user/actions';
import request from '../../services/request';
import { useWorkflowUser } from '../../redux/workflowUser/selectors';
import WorkflowUser from '../../components/Workflow/WorkflowUser';
import { fetchWorlflowUser } from '../../redux/workflowUser/actions';
import { isPatient } from '../../services/rights';

function UserProfile() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [workflow] = useWorkflowUser();
  const [[isLoading, error], setState] = useState([false, null]);
  const [user] = useUser();
  const roles = useRoles();

  const profile = useProfile();
  const update = async (values) => {
    setState([true, null]);
    try {
      const { data: p } = await request(`/users/${user.id}/profile`, 'POST', {
        ...values,
        contact_type: 1,
        birthday: moment(values.birthday).format(moment.HTML5_FMT.DATE),
      });
      dispatch(setProfile(p));
      dispatch(fetchWorlflowUser(user.id));
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      setState([false, null]);
    } catch (err) {
      message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
      setState([false, err]);
    }
  };

  return (
    <div>
      {workflow && workflow.step === 'create_medical_file' && isPatient(roles) && <WorkflowUser />}
      <Form
        layout="vertical"
        name="profile"
        onFinish={update}
        initialValues={profile}
        scrollToFirstError
      >
        <Profile />

        {error && <FormErrors err={error} />}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default UserProfile;
