import { SET_WORKFLOW_USER, WORKFLOW_USER_IS_FETCHING } from './actions';

const workflowUser = (state = [null, true], action) => {
  switch (action.type) {
    case SET_WORKFLOW_USER:
      return [action.workflow, false];
    case WORKFLOW_USER_IS_FETCHING:
      return [null, true];
    default:
      return state;
  }
};

export default workflowUser;
