import { DatePicker, Form, Input, InputNumber, Radio } from 'antd';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

function FormDynamic({ questions, responses, type = 'health' }) {
  const { t } = useTranslation();
  const renderItem = (q) => {
    const initialValue = responses.find((r) => r[`${type}_question_id`] === q.id)?.answer;

    if (q.type === 'date')
      return (
        <Form.Item
          initialValue={moment(initialValue)}
          label={q.question}
          name={q.id}
          key={q.id}
          rules={[{ required: q.required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <DatePicker />
        </Form.Item>
      );

    return (
      <Form.Item
        name={q.id}
        initialValue={initialValue}
        key={q.id}
        label={q.question}
        rules={[{ required: q.required, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
      >
        {(() => {
          if (q.type === 'string') return <TextArea rows={2} />;
          if (q.type === 'integer') return <InputNumber />;
          if (q.type === 'boolean')
            return (
              <Radio.Group>
                <Radio value="1">{t('FRONT_COMMON_TRUE')}</Radio>
                <Radio value="0">{t('FRONT_COMMON_FALSE')}</Radio>
              </Radio.Group>
            );
          return null;
        })()}
      </Form.Item>
    );
  };

  return questions.map((q) => renderItem(q));
}

FormDynamic.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.any).isRequired,
  responses: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default FormDynamic;
