import { Button, Descriptions, Form, message, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HealthForm from '../../components/MedicalFile/HealthForm';
import { usePatient } from '../../redux/medicalFile/selectors';

function Health({ medicalFile }) {
  const { t } = useTranslation();
  const [patient, isFetching] = usePatient();
  if (isFetching) return <Skeleton />;
  const { profile } = patient;

  return (
    <div id="header">
      {profile && (
        <Descriptions
          className="descriptions"
          bordered
          column={1}
          style={{ marginBottom: 24 }}
          labelStyle={{ backgroundColor: 'white' }}
        >
          <Descriptions.Item label={t('FRONT_USER_SIZE')}>{profile.height}</Descriptions.Item>
          <Descriptions.Item label={t('FRONT_USER_WEIGHT')}>{profile.weight}</Descriptions.Item>
        </Descriptions>
      )}
      <HealthForm medicalFile={medicalFile} />
    </div>
  );
}

Health.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Health;
