/* eslint-disable react/prop-types */
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchDocuments } from '../../redux/documents/actions';
import { url } from '../../services/request';

function UploadPostal({ medicalFile, index }) {
  const dispatch = useDispatch();

  return (
    <Upload
      name="file"
      action={`${url}/medicalfiles/${medicalFile.reference}/files/${index}`}
      onChange={(info) => {
        if (info.file.status === 'done') dispatch(fetchDocuments(medicalFile));
      }}
      withCredentials
    >
      <Button type="primary" icon={<UploadOutlined />} />
    </Upload>
  );
}

export default UploadPostal;
