/* eslint-disable react/prop-types */

import { Form, Button, message, Skeleton, Descriptions } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Flex from '../Flex';
import FormDynamic from '../form/FormDynamic';
import FormErrors from '../form/FormErrors';
import { nextStep } from '../../redux/step/actions';
import { createDoctorAnswers, fetchDoctorAnswers, fetchDoctorQuestions } from '../../services/api';
import { groupBy } from '../../services/helper';
import FormInfos from '../infos/FormInfos';
import { isExpertDoctor } from '../../services/rights';
import { useRoles } from '../../redux/user/selectors';

function DoctorForm({ medicalFile, agree, goBack, fetchAnswersQuestions, answers, questions }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [onEdit, setOnEdit] = useState(true);
  const [[isSaving, saveErr], setIsSaving] = useState([false, null]);
  const roles = useRoles();

  const { reference } = medicalFile;

  const save = async (values) => {
    setIsSaving([true, null]);
    const arr = Object.entries(values)
      .filter(([, v]) => v)
      .map(([k, v]) => [k, v.toString()]);
    const a = arr.map(([key, answer]) => ({ doctor_question_id: Number(key), answer }));
    try {
      await createDoctorAnswers(reference, {
        medical_file_id: medicalFile.id,
        answers: a,
      });
      if (agree) {
        dispatch(nextStep(reference));
      }
      await fetchDoctorAnswers(reference);
      await fetchAnswersQuestions();
      setIsSaving([false, null]);
      message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
    } catch (err) {
      setIsSaving([false, err]);
      message.error(t('FRONT_NOTIFICATION_SAVE_FAILED'));
    }
  };

  useEffect(() => {
    if (answers.length > 8) {
      setOnEdit(false);
    }
  }, [answers]);

  if (isSaving) return <Skeleton />;
  if (onEdit)
    return (
      <Form layout="vertical" name="Doctor" onFinish={save} scrollToFirstError>
        {questions.map((cat) => (
          <div key={cat.id}>
            <Title level={4} className="medicalfiles__category">
              {cat.category}
            </Title>
            {cat.groupBy && (
              <FormDynamic questions={cat.groupBy} responses={answers} type="doctor" />
            )}
          </div>
        ))}

        <FormErrors err={saveErr} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isSaving}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    );
  return (
    <>
      {answers.length > 6 && // on aaffiche uniquement le questionnaire si il n'y a aucune réponse pour les questions manquantes ou uniquement après avoir répondu aux question manquantes soient > 6
        questions.map((cat) => (
          <div key={cat.id}>
            <Title level={4} className="medicalfiles__category">
              {cat.category}
            </Title>
            <FormInfos questions={cat} answers={answers} type="doctor" />
          </div>
        ))}
      {answers.length > 0 && isExpertDoctor(roles) && (
        <Flex style={{ justifyContent: 'flex-end' }}>
          <Button
            type={onEdit ? 'danger' : 'primary'}
            shape="round"
            icon={onEdit ? <ArrowLeftOutlined /> : <EditOutlined />}
            onClick={() => setOnEdit(!onEdit)}
          />
        </Flex>
      )}
    </>
  );
}

export default DoctorForm;
