import { message } from 'antd';
import { fetchMedicalFilesDocuments, fetchUserDocuments } from '../../services/api';
import { groupBy } from '../../services/helper';
import request from '../../services/request';

export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const FETCH_DOCUMENTS_IS_FETCHING = 'FETCH_DOCUMENTS_IS_FETCHING';
export const FETCH_DOCUMENTS_STOP_FETCHING = 'FETCH_DOCUMENTS_STOP_FETCHING';

const setDocuments = (documents) => ({ type: SET_DOCUMENTS, documents });
const isFetching = () => ({ type: FETCH_DOCUMENTS_IS_FETCHING });
const stopFetching = (err) => ({ type: FETCH_DOCUMENTS_STOP_FETCHING, err });

export const fetchDocuments = (medicalfile) => async (dispatch, getState) => {
  dispatch(isFetching());
  const files = [];
  try {
    if (medicalfile) {
      const medicalFiles = await fetchMedicalFilesDocuments(medicalfile.reference);
      files.push(...medicalFiles);
      const userFiles = await fetchUserDocuments(medicalfile.user.id);
      files.push(...userFiles);
    } else {
      const currentUserFiles = await fetchUserDocuments(getState().user[0].id);
      files.push(...currentUserFiles);
    }
    dispatch(setDocuments(files));
  } catch {
    dispatch(setDocuments(files));
  }
};

const upload = async (medicalFile, userId, file, values) => {
  const formData = new FormData();
  formData.append('file', file.originFileObj);
  formData.append('name', values.name);
  formData.append('file_type_id', values.file_type_id);
  if (medicalFile) formData.append('medical_file_id', medicalFile.id);
  return medicalFile
    ? request(`/medicalfiles/${medicalFile.reference}/files`, 'POST', formData, true)
    : request(`/users/${userId}/files`, 'POST', formData, true);
};

export const createDocuments = (medicalFile, userId, values) => async (dispatch) => {
  dispatch(isFetching());
  try {
    if (values.postal) {
      const v = { ...values, postal_date: new Date(), postal: 1 };
      if (medicalFile) await request(`/medicalfiles/${medicalFile.reference}/files`, 'POST', v);
      else await request(`/users/${userId}/files`, 'POST', v);
    } else {
      const files = values.file.fileList;
      await Promise.all(files.map((f) => upload(medicalFile, userId, f, values)));
    }
    await dispatch(fetchDocuments(medicalFile));
  } catch (err) {
    dispatch(stopFetching(err.message || err));
  }
};

export const removeDocument = (reference, userId, uuid) => async (dispatch) => {
  dispatch(isFetching());
  try {
    if (reference) await request(`/medicalfiles/${reference}/files/${uuid}`, 'DELETE');
    if (userId) await request(`/users/${userId}/files/${uuid}`, 'DELETE');
    await dispatch(fetchDocuments(reference));
  } catch (err) {
    dispatch(stopFetching(err.message || err));
  }
};
