import { usePermissions } from '../redux/permissions/selectors';

const useHasPermission = () => {
  const permissions = usePermissions();

  const hasPermission = (name) => permissions.find((p) => p.name === name);

  return hasPermission;
};

export default useHasPermission;
