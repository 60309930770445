/* eslint-disable react/prop-types */
import { Button, Space, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSMOStepActive from '../../../hooks/useSMOStepActive';
import { nextStep } from '../../../redux/step/actions';
import { fetchAllSMOTranslations, fetchSMO } from '../../../services/api';
import { url } from '../../../services/request';

function FinalSMO({ reference }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [[state, isFetching], setState] = useState([[], true]);
  const [[translations, translationsIsFetching], setTranslations] = useState([[], true]);
  const { isStep } = useSMOStepActive();

  const fetchData = useCallback(() => {
    if (!reference || !state[0]?.index) return;
    fetchAllSMOTranslations(reference, state.index)
      .then((s) => {
        if (Array.isArray(s) && s.length > 0) {
          setTranslations([[s[s.length - 1]], false]);
        } else {
          setTranslations([[], false]);
        }
      })
      .catch(() => setTranslations([[], false]));
  }, [reference, state]);

  useEffect(() => {
    fetchSMO(reference)
      .then((s) => setState([Array.isArray(s.data) ? s.data : [s.data], false]))
      .catch(() => setState([[], false]));
  }, [reference]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size' },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              if (isStep('patient.viewsmo')) dispatch(nextStep(reference));
              history.push('/viewer', {
                size: value.size,
                url: `/medicalfiles/${reference}/smo/${value.index}`,
              });
            }}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <Button type="primary">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${url}/medicalfiles/${reference}/smo/${value.index}`}
            >
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>
        </Space>
      ),
    },
  ];

  const columns2 = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size' },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              history.push('/viewer', {
                url: `/translations/${value.index}`,
              })
            }
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <Button type="primary">
            <a target="_blank" rel="noreferrer" href={`${url}/translations/${value.index}`}>
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>
        </Space>
      ),
    },
  ];

  if (!state) return null;
  return (
    <>
      <Table
        key={Math.random()}
        rowKey="id"
        dataSource={state}
        columns={columns}
        loading={isFetching}
      />
      <Title level={4}>{t('FRONT_SMO_TRANSLATIONS')}</Title>
      <Table
        rowKey="id"
        key={Math.random()}
        dataSource={translations}
        columns={columns2}
        loading={translationsIsFetching}
      />
    </>
  );
}

export default FinalSMO;
