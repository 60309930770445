import React from 'react';
import Home from '../pages/Home';
import Login from '../pages/Authentication/Login';
import Register from '../pages/Authentication/Register';
import Contact from '../pages/Informations/Contact';
import PasswordForgot from '../pages/Authentication/PasswordForgot';
import PasswordReset from '../pages/Authentication/PasswordReset';
import FAQ from '../pages/Informations/FAQ';
import LegalTerms from '../pages/Informations/LegalTerms';
import PrivacyPolicy from '../pages/Informations/PrivacyPolicy';
import PasswordCreate from '../pages/Authentication/PasswordCreate';
import DataProtectionPolicy from '../pages/Informations/DataProtectionPolicy';

const routes = [
  {
    component: <Home />,
    exact: true,
    path: '/',
  },
  {
    component: <Login />,
    exact: true,
    path: '/login',
  },
  {
    component: <PasswordForgot />,
    exact: true,
    path: '/password/forgot',
  },
  {
    component: <PasswordReset />,
    exact: true,
    path: '/password/reset',
  },
  {
    component: <PasswordCreate />,
    exact: true,
    path: '/password/create',
  },
  {
    component: <Register />,
    exact: true,
    path: '/register',
  },
  {
    component: <Contact />,
    exact: true,
    path: '/contact',
  },
  {
    component: <FAQ />,
    exact: true,
    path: '/faq',
  },
  {
    component: <LegalTerms />,
    exact: true,
    path: '/cgu',
  },
  {
    component: <PrivacyPolicy />,
    exact: true,
    path: '/policy',
  },
  {
    component: <DataProtectionPolicy />,
    exact: true,
    path: '/data-protection-policy',
  },
];

export default routes;
