/* eslint-disable react/prop-types */
import { Form, Select, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function SelectStep({ steps }) {
  const { t } = useTranslation();

  return (
    <Form.Item label={t('FRONT_MEDICAL_FILE_STEP')} name="step">
      <Select>
        {steps.map((m, i) => (
          <Select.Option key={`step-${m}`} value={i}>
            {m}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectStep;
