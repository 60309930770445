import { Card, Form, Col, Row, Button, Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';

import SMOImage from '../../assets/images/SMO.jpg';
import T4TImage from '../../assets/images/T4T.jpg';

function SelectMedicalFileType({ onChange }) {
  const { t } = useTranslation();

  const handleClick = (val) => {
    onChange?.(val);
  };
  return (
    <Row gutter={16} align="stretch">
      <Col sm={24} md={12}>
        <MedicalFileTypeCard
          title={t('FRONT_MEDICAL_FILES_SMO_TITLE')}
          description={t('FRONT_MEDICAL_FILES_SMO_DESCRIPTION')}
          backgroundImage={SMOImage}
          action={t('FRONT_MEDICAL_FILES_SMO_ACTION')}
          onClick={() => handleClick(0)}
        />
      </Col>
      <Col sm={24} md={12}>
        <MedicalFileTypeCard
          title={t('FRONT_MEDICAL_FILES_T4T_TITLE')}
          description={t('FRONT_MEDICAL_FILES_T4T_DESCRIPTION')}
          backgroundImage={T4TImage}
          action={t('FRONT_MEDICAL_FILES_T4T_ACTION')}
          onClick={() => handleClick(1)}
        />
      </Col>
    </Row>
  );
}

export default SelectMedicalFileType;

SelectMedicalFileType.propTypes = {
  onChange: PropTypes.func.isRequired,
};

function MedicalFileTypeCard({ title, description, backgroundImage, action, onClick }) {
  return (
    <Card
      hoverable
      key={Math.random()}
      className="card__medicalfiletype"
      cover={<img src={backgroundImage} alt={title} className="card__medicalfiletype__cover" />}
      bodyStyle={{ flexGrow: 1 }}
      onClick={onClick}
      actions={[
        <div
          type="link"
          className="card__medicalfiletype__action"
          style={{ fontSize: 16, lineHeight: 1 }}
        >
          <Text style={{ maxWidth: '100%' }} ellipsis>
            {action}
          </Text>
        </div>,
      ]}
    >
      <Card.Meta title={title} description={description} />
    </Card>
  );
}

MedicalFileTypeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
