import { RightCircleFilled } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import request from '../../services/request';

function Contact() {
  const { t } = useTranslation();
  const [state, setState] = useState({ isFetching: false, err: null });
  const [captcha, setCaptcha] = useState(null);

  const send = async (values) => {
    setState({ isFetching: true });
    try {
      const res = await request('/contactform', 'POST', { ...values, key: captcha.key });
      setState({ isFetching: false, success: true });
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  const getCaptcha = async () => {
    const data = await request('/captcha/api');
    setCaptcha(data);
  };

  return (
    <div className="container">
      <Title>Contact</Title>
      <Form layout="vertical" name="register" onFinish={send}>
        <Form.Item
          label={t('FRONT_CONTACT_TYPE_QUESTION')}
          name="type"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Select placeholder={t('FRONT_COMMON_SELECT')}>
            <Select.Option key={1} value={0}>
              {t('FRONT_CONTACT_TYPE_1')}
            </Select.Option>
            <Select.Option key={2} value={1}>
              {t('FRONT_CONTACT_TYPE_2')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('FRONT_USER_FIRST_NAME')}
          name="firstname"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_LAST_NAME')}
          name="lastname"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_ENTITY')}
          name="company"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_ROLE')}
          name="role"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_EMAIL')}
          name="email"
          rules={[{ type: 'email' }, { required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_CONTACT_COMMENTS')}
          name="comments"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="captcha"
          label="Captcha"
          extra={t('FRONT_CONTACT_CAPTCHA_INFO')}
          rules={[{ required: true, message: '' }]}
        >
          <Row gutter={8}>
            {captcha !== null && (
              <Col span={12}>
                <img key={Math.random()} alt="captcha" src={captcha.img} />
              </Col>
            )}
            <Col span={12}>
              <Button onClick={getCaptcha}>
                {captcha !== null ? t('FRONT_COMMON_RELOAD') : t('FRONT_COMMON_LOAD')} captcha
              </Button>
            </Col>
          </Row>
          {captcha !== null && (
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="captcha"
                  rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form.Item>

        {state.success && (
          <Form.Item>
            <Alert message={t('FRONT_CONTACT_SUCCESS')} type="success" />
          </Form.Item>
        )}

        <FormErrors err={state.err} />

        <Button
          type="primary"
          htmlType="submit"
          disabled={captcha === null}
          loading={state.isFetching}
        >
          {t('FRONT_FORM_SEND')}
        </Button>
      </Form>
    </div>
  );
}

export default Contact;
