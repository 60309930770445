/* eslint-disable react/prop-types */
import { Table } from 'antd';
import React from 'react';

const setField = (field) => {
  if (field.includes('company')) return 'company';
  if (field === 'roles') return 'role';
  if (field === 'countries') return 'country';
  if (Array.isArray(field) && field[0] === 'step') return 'step';
  if (Array.isArray(field)) return field.join('.');
  return field;
};

function TableCustom({ state, columns, isFetching, fetchData }) {
  const data = state?.data || [];
  const meta = state?.meta;

  return (
    <Table
      rowKey={() => Math.random()}
      dataSource={data}
      columns={columns}
      loading={isFetching}
      scroll={{ x: true }}
      pagination={{
        current: meta?.current_page,
        pageSize: meta?.per_page,
        total: meta?.total,
      }}
      onChange={(pagination, _, sorter) => {
        const order =
          sorter && sorter.field && sorter.order
            ? {
                property: setField(sorter.field),
                direction: sorter.order === 'ascend' ? 'asc' : 'desc',
              }
            : null;
        fetchData(pagination.current, order);
      }}
    />
  );
}

export default TableCustom;
