/* eslint-disable react/no-unescaped-entities */
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../services/i18n';
import EN from './PDF/data-protection-policy-en.pdf';
import FR from './PDF/data-protection-policy-fr.pdf';

function DataProtectionPolicy() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Title> {t('FRONT_SIDEBAR_DATA_PROTECTION_POLICY')}</Title>
      <embed
        style={{ width: '100%', height: 'calc(100vh - 200px)' }}
        key={Math.random()}
        src={i18n.language === 'fr' ? FR : EN}
      />
    </div>
  );
}

export default DataProtectionPolicy;
