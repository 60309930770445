/* eslint-disable react/prop-types */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Space, Table } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { useFileTypes } from '../../redux/metadata/selectors';
import { groupBy } from '../../services/helper';
import UploadPostal from './UploadPostal';
import acceptFileType from '../../services/AcceptFileType';
import { url } from '../../services/request';
import { useDocuments } from '../../redux/documents/selectors';

const styles = { icon: { fontSize: 18, color: 'red', cursor: 'pointer' } };

function DocumentsList({ documents, isFetching, onRemove }) {
  const { t } = useTranslation();
  const history = useHistory();
  const fileTypes = useFileTypes();
  const [medicalFile] = useMedicalFile();
  const [, , error] = useDocuments();

  const { reference } = medicalFile;

  useEffect(() => {
    if (error) console.error(error);
  }, [error]);

  const documentsUpload = useMemo(() => {
    if (documents !== null) {
      return groupBy(
        documents?.filter((d) => d.uploaded || d.user_id),
        'file_type_id'
      );
    }
    return [];
  }, [documents]);
  const documentsPostal = documents?.filter((d) => !d.uploaded && !d.user_id);
  const getTotal = (files) => {
    let total = 0;
    files.map((file) => {
      total += file.size;
      return file;
    });
    return total;
  };

  const columns = [
    {
      title: t('FRONT_DOCUMENT_TYPE'),
      dataIndex: 'file_type_id',
      render: (value) => fileTypes.find((f) => f.id === value)?.alias,
    },
    {
      title: t('FRONT_DOCUMENT_COUNT'),
      dataIndex: 'groupBy',
      render: (value) => value?.length.toString(),
    },
    {
      key: 'action',
      render: (value) => {
        if (!value.groupBy.find((v) => !v.canView || v.user_id))
          return (
            <Space>
              <Button
                type="primary"
                onClick={async () => {
                  history.push('/viewer', {
                    length: getTotal(value.groupBy),
                    url: null,
                    reference,
                    files: value.groupBy,
                  });
                }}
                disabled={!value.groupBy.find((v) => acceptFileType(v))}
              >
                {t('FRONT_DOCUMENT_SHOW')}
              </Button>
              <Button type="primary" disabled={value.groupBy.find((v) => !v.canView || v.user_id)}>
                <a href={`${url}/medicalfiles/${reference}/files/zip/${value.file_type_id}`}>
                  {t('FRONT_COMMON_DOWNLOAD')}
                </a>
              </Button>
            </Space>
          );
        return null;
      },
    },
  ];

  const columnsChild = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size', render: (v) => Math.floor(v / 1000) },
    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              history.push('/viewer', {
                length: value.size,
                url: null,
                reference,
                files: [value],
              });
            }}
            disabled={!acceptFileType(value) || (!value.canView && !value.user_id)}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <Button type="primary" disabled={!value.canView && !value.user_id}>
            <a
              target="_blank"
              rel="noreferrer"
              href={
                value.user_id
                  ? `${url}/users/${value.user_id}/files/${value.index}`
                  : `${url}/medicalfiles/${reference}/files/${value.index}`
              }
            >
              {t('FRONT_COMMON_DOWNLOAD')}
            </a>
          </Button>
          <Button
            type="primary"
            onClick={() => history.push('/translation', { index: value.index, reference })}
          >
            {`${t('FRONT_DOCUMENT_SHOW_TRADUCTIONS')} (${value.translations_count || 0})`}
          </Button>
          <DeleteOutlined onClick={() => onRemove(value.index)} style={styles.icon} />
        </Space>
      ),
    },
  ];

  const columnsPostal = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_POSTAL_TYPE'), dataIndex: 'postal_type' },
    { title: t('FRONT_DOCUMENT_POSTAL_NUMBER'), dataIndex: 'postal_number' },
    {
      key: 'action',
      dataIndex: 'index',
      render: (value) => <UploadPostal medicalFile={medicalFile} index={value} />,
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        dataSource={documentsUpload}
        columns={columns}
        loading={isFetching}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              rowKey="id"
              columns={columnsChild}
              dataSource={record.groupBy}
              pagination={false}
            />
          ),
        }}
      />
      <Title level={5} style={{ marginTop: 10 }}>
        {t('FRONT_DOCUMENT_POSTAL')}
      </Title>
      <Table
        rowKey="id"
        dataSource={documentsPostal}
        columns={columnsPostal}
        loading={isFetching}
      />
    </>
  );
}

export default DocumentsList;
