import { Button, Form, Input, Row, Col, Space, Divider, Checkbox } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import SelectCivility from '../../components/form/SelectCivility';
import useQuery from '../../hooks/useQuery';
import { fetchUser } from '../../redux/user/actions';
import request from '../../services/request';

const styles = {
  form: { minWidth: 350, width: '60%', margin: 'auto' },
  divider: { margin: '16px 0' },
  background: {
    backgroundImage: 'url(/bg-connexion.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    minHeight: 200,
    width: '100%',
  },
  rightside: {
    padding: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: { paddingLeft: 0, fontWeight: 'bold', fontSize: 'inherit' },
};

function Register() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = useQuery();
  const prevLocation = location.state?.prevLocation;
  const referral = query.get('referral');
  const [state, setState] = useState({ isFetching: false, err: null });

  const signIn = (credentials) => {
    setState({ isFetching: true, err: null });
    request('/login', 'POST', credentials)
      .then(async () => {
        dispatch(fetchUser(prevLocation, history));
      })
      .catch((err) => setState({ isFetching: false, err }));
  };
  const create = async (values) => {
    setState({ isFetching: true, err: null });
    return request('/register', 'POST', { referral, language: i18n.language, ...values })
      .then(() => history.push('/login'))
      .catch((err) => setState({ isFetching: false, err }));
  };

  return (
    <Row style={{ minHeight: 'calc(100vh - 64px)' }} justify="center" align="stretch">
      <Col sm={24} md={12} style={styles.background} />
      <Col sm={24} md={12} style={styles.rightside}>
        <div>
          <Title style={{ textAlign: 'center' }}>{t('FRONT_REGISTER_TITLE')}</Title>
          <Form style={styles.form} layout="vertical" name="register" onFinish={create}>
            <SelectCivility hasFeedback />
            <Form.Item
              name="firstname"
              label={t('FRONT_USER_FIRST_NAME')}
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="lastname"
              label={t('FRONT_USER_LAST_NAME')}
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={t('FRONT_USER_EMAIL')}
              rules={[
                { type: 'email' },
                { required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label={t('FRONT_USER_PASSWORD_NEW')}
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
              ]}
              hasFeedback
              extra={t('FRONT_USER_PASSWORD_RULES')}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="password_confirmation"
              label={t('FRONT_USER_PASSWORD_CONFIRM')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('FRONT_FORM_MESSAGE_REQUIRED'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('FRONT_USER_PASSWORD_NOTMATCH')));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <FormErrors err={state.err} />

            <Button
              style={styles.button}
              type="primary"
              htmlType="submit"
              loading={state.isFetching}
              block
            >
              {t('FRONT_REGISTER_REGISTER')}
            </Button>

            <Divider style={styles.divider}>{t('FRONT_REGISTER_LOGIN_QUESTION')}</Divider>

            <Button
              style={styles.button}
              type="primary"
              block
              onClick={() => history.push('/login')}
            >
              {t('FRONT_REGISTER_LOGIN')}
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default Register;
