/* eslint-disable no-undef */
import { EyeOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import Error404 from '../../components/Error404';
import TableCustom from '../../components/Table';
import useHasPermission from '../../hooks/useHasPermission';
import { usePathology } from '../../redux/metadata/selectors';
import { fetchAllUserMedicalFiles, fetchUser } from '../../services/api';

const renderTag = (value) => {
  if (value === 0) return <Tag color="green">Close</Tag>;
  if (value === 1) return <Tag color="orange">Waiting</Tag>;
  return <Tag color="red">Open</Tag>;
};

function UserMedicalFiles() {
  const { t } = useTranslation();
  const params = useParams();
  const hasPermission = useHasPermission();
  const pathologies = usePathology();
  const userId = useMemo(() => params?.userId, [params]);

  const [[state, isFetching], setState] = useState([null, true]);
  const [user, setUser] = useState(null);

  const fetchData = useCallback(
    (page) => {
      fetchAllUserMedicalFiles(userId, page)
        .then((s) => setState([s, false]))
        .catch(() => setState([null, false]));
    },
    [userId]
  );

  const getUser = useCallback(() => {
    fetchUser(userId)
      .then(({ data: u }) => setUser(u))
      .catch(() => setUser(null));
  }, [userId]);

  useEffect(() => {
    getUser();
    fetchData();
  }, [fetchData, getUser]);

  const columns = [
    {
      dataIndex: ['step', 'commitment'],
      render: (i) => renderTag(i),
    },
    {
      title: t('FRONT_MEDICAL_FILE_REFERENCE'),
      dataIndex: 'reference',
      render: (value) => <Link to={`/medicals/${value}`}>{value}</Link>,
    },
    hasPermission('view medicalfiles pathology')
      ? {
          title: t('FRONT_MEDICAL_FILE_PATHOLOGY'),
          dataIndex: 'pathology',
          render: (i) => pathologies.find((p) => p.id === i).name,
        }
      : {},
    { title: t('FRONT_USER_FIRST_NAME'), dataIndex: ['user', 'firstname'] },
    { title: t('FRONT_USER_LAST_NAME'), dataIndex: ['user', 'lastname'] },
    {
      title: t('FRONT_USER_COUNTRIES'),
      dataIndex: ['user', 'countries'],
      render: (values) => values.map((v) => v.name).join(', '),
    },
    { title: t('FRONT_MEDICAL_FILE_STATUS'), dataIndex: ['step', 'description'] },
    {
      title: t('FRONT_MEDICAL_FILE_ACTION_USER'),
      dataIndex: 'step',
      render: (step) =>
        `${step?.action_user?.firstname || ''} ${step?.action_user?.lastname || ''}`,
    },
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'updated_at',
      render: (r) => new Date(r).toLocaleString(),
    },
    {
      key: 'action',
      render: (_, record) => (
        <Link to={`/medicals/${record.reference}`}>
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      ),
    },
  ];

  if (!userId) return <Error404 />;
  return (
    <>
      {user !== null && (
        <Title>
          {user.firstname} {user.lastname}
        </Title>
      )}
      <TableCustom state={state} columns={columns} isFetching={isFetching} fetchData={fetchData} />
    </>
  );
}

export default UserMedicalFiles;
