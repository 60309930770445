/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import MedicalFileDocuments from '../../../pages/MedicalFile/T4T/Documents';
import Satisfaction from '../../../pages/MedicalFile/Satisfaction';
import Health from '../../../pages/MedicalFile/Health';
import Contact from '../../../pages/MedicalFile/T4T/Contact';
import Patient from '../../../pages/MedicalFile/Patient';
import MedicalFileDetails from '../../../pages/MedicalFile/Details';
import Travel from '../../../pages/MedicalFile/T4T/Travel';
import Management from '../../../pages/MedicalFile/T4T/Management';
import Doctor from '../../../pages/MedicalFile/T4T/Doctor';
import { useRoles } from '../../../redux/user/selectors';
import {
  isClinicCoordinator,
  isExpertDoctor,
  isAdmin,
  isIntermediateCoordinator,
} from '../../../services/rights';
import Comments from '../../MedicalFile/Comments';

function T4TRouter({ medicalFile }) {
  const roles = useRoles();
  const { t } = useTranslation();
  return (
    <Switch>
      <Route path="/medicals/:reference" exact>
        <MedicalFileDetails medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/patient" exact>
        <Patient medicalFile={medicalFile} />
        {(isExpertDoctor(roles) ||
          isClinicCoordinator(roles) ||
          isAdmin(roles) ||
          isIntermediateCoordinator(roles)) && (
          <Collapse style={{ marginTop: 20 }}>
            <Collapse.Panel header={t('FRONT_MEDICAL_FILE_COMMENTS_PATIENT')} key={4}>
              <Comments url={`/medicalfiles/${medicalFile.reference}/comments `} />
            </Collapse.Panel>
          </Collapse>
        )}
      </Route>
      <Route path="/medicals/:reference/health" exact>
        <Health medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/documents" exact>
        <MedicalFileDocuments medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/travel" exact>
        <Travel medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/doctor" exact>
        <Doctor medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/management" exact>
        <Management medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/contact" exact>
        <Contact medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/satisfaction" exact>
        <Satisfaction medicalFile={medicalFile} />
      </Route>
      <Route>
        <MedicalFileDetails medicalFile={medicalFile} />
      </Route>
    </Switch>
  );
}

export default T4TRouter;
