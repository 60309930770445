import { Steps } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Workflow from '..';
import { useMedicalFile } from '../../../redux/medicalFile/selectors';
import { fetchStep, nextStep } from '../../../redux/step/actions';
import { useStep } from '../../../redux/step/selectors';
import Loading from '../../layouts/Loading';

function SMOWorflow() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, isFetching] = useStep();
  const [medicalFile] = useMedicalFile();

  useEffect(() => {
    dispatch(fetchStep(medicalFile.reference));
  }, [dispatch, medicalFile.reference]);

  const steps = useMemo(
    () => [
      {
        reference: 'patient.stepfillmedicalform',
        next: () => history.push(`/medicals/${medicalFile.reference}/health`),
        autoNext: true,
      },
      {
        reference: 'patient.stepuploadmedicalfiles',
        next: () => history.push(`/medicals/${medicalFile.reference}/documents`, { defaultTab: 1 }),
        shouldValidate: true,
      },
      {
        reference: 'admin.stepwaitingforsmopayment',
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        shouldValidate: true,
        doubleCheck: true,
      },
      {
        reference: 'admin.stepassigndoctor',
        next: () => history.replace(`/medicals/${medicalFile.reference}`, { edit: true }),
        autoNext: true,
      },
      {
        reference: 'doctor.stepvalidatemedicalfile',
        next: () => history.push(`/medicals/${medicalFile.reference}/documents`, { defaultTab: 2 }),
        shouldValidate: true,
      },
      {
        reference: 'doctor.stepdosmo',
        next: () => history.push(`/medicals/${medicalFile.reference}/smo`),
      },
      {
        reference: 'patient.viewsmo',
        next: async () => history.push(`/medicals/${medicalFile.reference}/smo/final`),
      },
      {
        reference: 'any.waitingforinvoice',
        next: () => history.push(`/medicals/${medicalFile.reference}/documents`, { defaultTab: 1 }),
      },
      {
        reference: 'patient.stepfillsatisfactionform',
        next: () => history.push(`/medicals/${medicalFile.reference}/satisfaction`),
      },
      {
        reference: 'any.fileclosed',
        next: null,
      },
    ],
    [history, medicalFile]
  );
  if (isFetching) return <Loading />;
  return <Workflow steps={steps} />;
}

export default SMOWorflow;
