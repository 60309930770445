import { Button, Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { useRoles } from '../../redux/user/selectors';
import { useWorkflowUser } from '../../redux/workflowUser/selectors';
import { isPatient } from '../../services/rights';
import Flex from '../Flex';
import WorkflowUser from '../Workflow/WorkflowUser';
import Header from './Header';
import Sider from './Sider';

function LayoutPrivate({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [user] = useSelector((state) => state.user);
  const roles = useRoles();
  const [workflow] = useWorkflowUser();

  if (!user)
    return <Redirect to={{ pathname: '/login', state: { prevLocation: location.pathname } }} />;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout className="content" hasSider>
        <Sider />
        <Layout>
          <Content style={{ padding: 24 }}>
            {workflow && workflow.step === 'fill_profile' && isPatient(roles) && <WorkflowUser />}

            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

LayoutPrivate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LayoutPrivate;
