import { SET_MEDICAL_FILE, MEDICAL_FILE_IS_FETCHING } from './actions';

const medicalFile = (state = [null, false], action) => {
  switch (action.type) {
    case SET_MEDICAL_FILE:
      return [action.medicalFile, false];
    case MEDICAL_FILE_IS_FETCHING:
      return [null, true];
    default:
      return state;
  }
};

export default medicalFile;
