import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Alert } from 'antd';
import { RightOutlined } from '@ant-design/icons';

function DocumentInfo() {
  const { t } = useTranslation();

  return (
    <Alert
      style={{ marginBottom: 24 }}
      message="Important"
      description={
        <Trans i18nKey="FRONT_DOCUMENTS_INFO">
          Pour envoyer votre dossier médical à notre équipe, merci d&apos;ajouter au moins un des
          documents suivants :
          <ul>
            <li>Dernière prise de sang</li>
            <li>Imagerie médicale</li>
          </ul>
          Nous vous informons que le téléchargement de vos fichiers peut prendre du temps. Merci
          pour votre patience.
        </Trans>
      }
      type="warning"
      showIcon
      closable
    />
  );
}

export default DocumentInfo;
