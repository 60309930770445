import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Space, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import TableCustom from '../../components/Table';
import useHasPermission from '../../hooks/useHasPermission';
import { deleteCompany, fetchCompanies } from '../../services/api';
import { useCountries } from '../../redux/metadata/selectors';

const styles = { icon: { fontSize: 18, cursor: 'pointer' } };

function EntitiesList() {
  const { t } = useTranslation();
  const [[state, isFetching], setState] = useState([null, true]);
  const hasPermission = useHasPermission();
  const countries = useCountries();
  const history = useHistory();

  const fetchData = (page, order) => {
    fetchCompanies(page, order)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));
  };

  const removeEntity = async (id) => {
    try {
      setState(([c]) => [c, true]);
      await deleteCompany(id);
      fetchData(state.meta.current_page);
      message.success(t('FRONT_NOTIFICATION_DELETE_SUCCESS'));
    } catch (err) {
      setState(([c]) => [c, false]);
      message.error(t('FRONT_NOTIFICATION_DELETE_FAILED'));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { title: t('FRONT_ENTITY_NAME'), dataIndex: 'name', sorter: true },
    { title: t('FRONT_ENTITY_EMAIL'), dataIndex: 'email', sorter: true },
    {
      title: t('FRONT_ENTITY_MAIN_COORDINATOR_EMAIL'),
      dataIndex: 'main_coordinator',
      sorter: true,
    },
    {
      title: t('FRONT_ENTITY_CREATE_PATIENT'),
      dataIndex: 'referral',
      render: (v) => (
        <Typography.Link
          onClick={() => history.push(`/register?referral=${v}`)}
        >{`${window.location.origin}/register?referral=${v}`}</Typography.Link>
      ),
    },
    { title: t('FRONT_ENTITY_CITY'), dataIndex: 'city', sorter: true },
    {
      title: t('FRONT_ENTITY_COUNTRY'),
      dataIndex: 'country',
      render: (v) => countries.find((c) => c.code === v)?.name,
    },
    {
      key: 'action',
      render: (_, item) => (
        <Space size="large">
          {hasPermission('update companies') && (
            <Link to={{ pathname: 'entities/add', state: { company: item } }}>
              <EditOutlined style={styles.icon} />
            </Link>
          )}
          {hasPermission('delete companies') && (
            <DeleteOutlined
              onClick={() => removeEntity(item.id)}
              style={{ ...styles.icon, color: 'red' }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title>{t('FRONT_ENTITY_LABELS')}</Title>
      <TableCustom state={state} columns={columns} isFetching={isFetching} fetchData={fetchData} />
    </div>
  );
}

export default EntitiesList;
