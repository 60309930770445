import { createFileDemand, fetchFileDemands } from '../../services/api';

export const SET_ASK_DOCUMENTS = 'SET_ASK_DOCUMENTS';
export const ASK_DOCUMENTS_IS_FETCHING = 'ASK_DOCUMENTS_IS_FETCHING';

const setAskDocuments = (askDocuments) => ({ type: SET_ASK_DOCUMENTS, askDocuments });
const isFetching = () => ({ type: ASK_DOCUMENTS_IS_FETCHING });

export const fetchAskDocuments = (reference, page) => async (dispatch) => {
  dispatch(isFetching());
  try {
    const documents = await fetchFileDemands(reference, page);
    dispatch(setAskDocuments(documents));
  } catch {
    dispatch(setAskDocuments(null));
  }
};

export const createAskDocuments = (reference, values) => async (dispatch) => {
  await createFileDemand(reference, values);
  dispatch(fetchAskDocuments(reference));
};
