import { Button, Descriptions, Form, message, Skeleton, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Error404 from '../components/Error404';
import FormErrors from '../components/form/FormErrors';
import Profile from '../components/Profile';
import { createProfile, fetchProfile, updateProfile } from '../services/api';

function UserProfile() {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();

  const [[profile, isFetching], setProfile] = useState([{}, true]);
  const [[isUpdating, error], setState] = useState([false, null]);

  useEffect(() => {
    if (params.userId)
      fetchProfile(params.userId)
        .then((p) => setProfile([{ ...p, birthday: moment(p.birthday) }, false]))
        .catch(() => setProfile([{}, false]));
  }, [params.userId]);

  const update = async (values) => {
    setState([true, null]);
    try {
      if (profile) await updateProfile(params.userId, values);
      else await createProfile(params.userId, values);
      setState([false, null]);
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
    } catch (err) {
      setState([false, err]);
      message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
    }
  };

  if (isFetching) return <Skeleton />;
  if (!params.userId) return <Error404 />;

  return (
    <Form layout="vertical" name="profile" onFinish={update} initialValues={profile}>
      <Profile />

      {profile.policy && (
        <Descriptions>
          <Descriptions.Item label={t('FRONT_USER_POLICY_NUMBER')} span={3}>
            {profile.policy.number}
          </Descriptions.Item>
          <Descriptions.Item label={t('FRONT_USER_POLICY_PRODUCTID')} span={3}>
            {profile.policy.product_id}
          </Descriptions.Item>
        </Descriptions>
      )}

      {profile.registration && (
        <Descriptions>
          <Descriptions.Item label={t('FRONT_USER_REGISTRATION_TYPE')} span={3}>
            {profile.registration.type}
          </Descriptions.Item>
          <Descriptions.Item label={t('FRONT_USER_REGISTRATION_NUMBER')} span={3}>
            {profile.registration.number}
          </Descriptions.Item>
          <Descriptions.Item label={t('FRONT_USER_REGISTRATION_COUNTRY')} span={3}>
            {profile.registration.country}
          </Descriptions.Item>
        </Descriptions>
      )}

      {error && <FormErrors err={error} />}

      <Form.Item>
        <Space>
          <Button type="primary" onClick={() => history.goBack()}>
            {t('FRONT_COMMON_BACK')}
          </Button>
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default UserProfile;
