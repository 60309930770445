import { DatePicker, Form, Input, InputNumber } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRoles } from '../redux/user/selectors';
import { isPatient } from '../services/rights';
import MobilePhone from './form/MobilePhone';
import SelectCivility from './form/SelectCivility';
import SelectCountries from './form/SelectCountries';
import SelectLanguages from './form/SelectLanguages';

function Profile() {
  const { t } = useTranslation();
  const roles = useRoles();
  return (
    <>
      <MobilePhone />
      <Form.Item
        label={t('FRONT_USER_ADDRESS')}
        name="address"
        rules={[
          {
            required: isPatient(roles),
            message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('FRONT_USER_ZIP')}
        name="zipcode"
        rules={[
          {
            required: isPatient(roles),
            message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('FRONT_USER_CITY')}
        name="city"
        rules={[
          {
            required: isPatient(roles),
            message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <SelectCountries keyValue="code" required />
      <Form.Item
        label={t('FRONT_USER_BIRTH_DATE')}
        name="birthday"
        rules={[
          {
            required: true,
            message: `${t('FRONT_USER_BIRTH_DATE')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label={t('FRONT_USER_SIZE')}
        name="height"
        rules={[
          {
            required: isPatient(roles),
            message: `${t('FRONT_USER_SIZE')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label={t('FRONT_USER_WEIGHT')}
        name="weight"
        rules={[
          {
            required: isPatient(roles),
            message: `${t('FRONT_USER_WEIGHT')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <SelectLanguages onlyEnglishAndFrench={false} required />
    </>
  );
}

export default Profile;
