import { ROLES_IS_FETCHING, SET_ROLES } from './actions';

const roles = (state = [[], false], action) => {
  switch (action.type) {
    case SET_ROLES:
      return [action.roles, false];
    case ROLES_IS_FETCHING:
      return [[], true];
    default:
      return state;
  }
};

export default roles;
