/* eslint-disable react/prop-types */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Comment, Form, Layout, Input, Button, Skeleton, message, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import request from '../services/request';
import { useUser } from '../redux/user/selectors';
import { isLocalDoctor } from '../services/rights';

const { Content } = Layout;
const { TextArea } = Input;

const styles = {
  content: { padding: '0 24px', minHeight: 280 },
  messages: { overflow: 'auto', maxHeight: 'calc(100vh - 500px)' },
};

function Messages({ medicalFile, conversation = null, interlocutor }) {
  const { t } = useTranslation();
  const conversationsRef = useRef();
  const formRef = useRef();
  const [[messages, isFetching], setState] = useState([[], true]);
  const [conversationId, setConversationId] = useState(conversation?.id);
  const [user] = useUser();

  const fetchMessages = useCallback(async () => {
    if (!conversationId) return setState([[], false]);
    try {
      const { data: m } = await request(`/conversations/${conversationId}/messages`, 'GET');
      const messagesSort = m.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
      return setState([messagesSort, false]);
    } catch (err) {
      return setState([null, [], false]);
    }
  }, [conversationId]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    if (conversationsRef.current)
      conversationsRef.current.scrollTop = conversationsRef.current.scrollHeight;
  }, [messages]);

  const send = async (values) => {
    try {
      if (!conversationId) {
        const { data: c } = await request(
          `/medicalfiles/${medicalFile.reference}/conversations`,
          'POST',
          {
            ...values,
            to_user_id: interlocutor.id,
            subject: `medicalFile_${medicalFile.id}`,
          }
        );
        setConversationId(c.id);
      } else {
        const { data } = await request(
          `/conversations/${conversation.id}/messages`,
          'POST',
          values
        );
        setState(([m]) => [[...m, data], false]);
      }
      if (formRef.current) formRef.current.resetFields();
      return message.success(t('FRONT_NOTIFICATION_SEND_SUCCESS'));
    } catch (err) {
      return message.error(t('FRONT_NOTIFICATION_SEND_FAILED'));
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <Content style={styles.content}>
      <div style={styles.messages} ref={conversationsRef}>
        {messages.map((m) => (
          <Comment
            key={m.id}
            author={`${m.user.firstname} ${m.user.lastname}`}
            avatar={<Avatar icon={<UserOutlined />} />}
            content={m.message}
            datetime={<span>{moment(m.updated_at).fromNow()}</span>}
          />
        ))}
      </div>

      <Comment
        avatar={<Avatar icon={<UserOutlined />} />}
        content={
          <Form ref={formRef} name="message" onFinish={send}>
            <Form.Item
              name="message"
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                {t('FRONT_FORM_SEND')}
              </Button>
            </Form.Item>
          </Form>
        }
      />
    </Content>
  );
}

export default Messages;
