import queryString from 'query-string';
import moment from 'moment';
import request, { requestV2 } from './request';

export const fetchAll = async (url) => {
  const first = await request(url);
  const lastPage = first.meta?.last_page || first.last_page;
  if (lastPage > 1) {
    const promises = [];
    for (let i = 2; i <= lastPage; i += 1) {
      promises.push(request(`${url}?page=${i}`));
    }
    (await Promise.all(promises)).forEach((f) => first.data.push(...f.data));
  }
  return first.data;
};

const cleanObject = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));

// USERS
export const fetchUser = (userId) => request(`/users/${userId}`);
export const createUser = (values) => request('/users', 'POST', values);
export const updateUser = (userId, values) => request(`/users/${userId}`, 'PUT', values);
export const updateUserPassword = (values) => request(`/user/password`, 'PUT', values);
export const deleteUser = (userId) => request(`/users/${userId}`, 'DELETE');

export const fetchUsersV2 = (page = 1, order) => requestV2(`/users/1/roles`);
export const fetchUsers = (page = 1, order) => {
  fetchUsersV2();
  return request(
    `/users?page=${page}${order ? `&sort=${order.property}&direction=${order.direction}` : ''}`
  );
};
export const fetchAllUsers = () => fetchAll('/users');
export const fetchAllPatients = (user) => {
  if (user.roles[0].name === 'Expert Doctor') {
    return fetchAll(`/users/${user.id}/patientsCreate`);
  }
  return fetchAll(`/users/${user.id}/patients`);
};

export const fetchPatients = (userId, page = 1, order) =>
  request(
    `/users/${userId}/patients?page=${page}${
      order ? `&sort=${order.property}&direction=${order.direction}` : ''
    }`
  );

// COMPANIES
export const fetchCompanies = (page = 1, order) =>
  request(
    `/companies?page=${page}${order ? `&sort=${order.property}&direction=${order.direction}` : ''}`
  );
export const fetchAllCompanies = () => fetchAll('/companies');
export const createCompany = (values) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => {
    if (values[key]) {
      formData.append(key, values[key]);
    }
  });
  return request('/companies', 'POST', formData, true);
};

export const updateCompany = (id, values) => {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(values).forEach((key) => {
    if (values[key]) {
      formData.append(key, values[key]);
    }
  });
  return request(`/companies/${id}`, 'POST', formData, true);
};
export const deleteCompany = (id) => request(`/companies/${id}`, 'DELETE');

// ROLES
export const fetchAllRoles = () => fetchAll('/roles');

// MEDICAL FILES
export const fetchMedicalFiles = (page = 1, order) =>
  request(
    `/medicalfiles?page=${page}${
      order ? `&sort=${order.property}&direction=${order.direction}` : ''
    }`
  );
export const fetchMedicalFile = (ref) => request(`/medicalfiles/${ref}`);
export const fetchMedicalActivities = (ref, page = 1) =>
  request(`/medicalfiles/${ref}/activity?page=${page}`);
export const fetchAllInterlocutors = (ref) => fetchAll(`/medicalfiles/${ref}/interlocutors`);
export const fetchMedicalFilePayment = (ref) => request(`/medicalfiles/${ref}/payment`);
export const fetchMedicalFileTravelData = (ref) => request(`/medicalfiles/${ref}/traveldata`);
export const fetchMedicalFileFileTypes = (ref) => request(`/medicalfiles/${ref}/filetypes`);
export const fetchAllUserMedicalFiles = (userId, page = 1) =>
  request(`/users/${userId}/medicalfiles?page=${page}`);
export const deleteMedicalfile = (ref) => request(`/medicalfiles/${ref}`, 'DELETE');

// FILE TYPES
export const fetchAllFileTypes = () => fetchAll('/filetypes');

// Documents
export const fetchUserDocuments = (userId) => fetchAll(`/users/${userId}/files`);
export const fetchMedicalFilesDocuments = (ref) => fetchAll(`/medicalfiles/${ref}/files`);

// HEALTH FORM
export const fetchHealthQuestions = (ref) => fetchAll(`/medicalfiles/${ref}/healthquestions`);
export const fetchHealthAnswers = (ref) => fetchAll(`/medicalfiles/${ref}/healthanswers`);
export const createHealthAnswers = (ref, values) =>
  request(`/medicalfiles/${ref}/healthanswers`, 'POST', values);

// TRAVEL FORM
export const fetchTravelQuestions = (ref) => fetchAll(`/medicalfiles/${ref}/travelquestions`);
export const fetchTravelAnswers = (ref) => fetchAll(`/medicalfiles/${ref}/travelanswers`);
export const createTravelAnswers = (ref, values) =>
  request(`/medicalfiles/${ref}/travelanswers`, 'POST', values);

// DOCTOR FORM
export const fetchDoctorQuestions = (ref) => fetchAll(`/medicalfiles/${ref}/doctorquestions`);
export const fetchDoctorAnswers = (ref) => fetchAll(`/medicalfiles/${ref}/doctoranswers`);
export const createDoctorAnswers = (ref, values) =>
  request(`/medicalfiles/${ref}/doctoranswers`, 'POST', values);

// FILE TRANSLATIONS
export const fetchFileTranslations = (ref, index, page = 1) =>
  request(`/medicalfiles/${ref}/files/${index}/translations?page=${page}`);
export const fetchSMOTranslations = (ref, index, page = 1) =>
  request(`/medicalfiles/${ref}/smo/${index}/translations?page=${page}`);
export const fetchAllSMOTranslations = (ref, index) =>
  fetchAll(`/medicalfiles/${ref}/smo/${index}/translations`);

// FILE DEMANDS
export const fetchAllFileDemands = (ref) => fetchAll(`/medicalfiles/${ref}/demands`);
export const fetchFileDemands = (ref, page = 1) =>
  request(`/medicalfiles/${ref}/demands?page=${page}`);
export const createFileDemand = (ref, values) =>
  request(`/medicalfiles/${ref}/demands`, 'POST', values);

// SMO
export const fetchSMO = (ref) => request(`/medicalfiles/${ref}/smo`);
export const createSMO = (ref, values) => request(`/medicalfiles/${ref}/smo`, 'POST', values);

// SATISFACTION FORM
export const fetchSatisfactionQuestions = (ref) =>
  fetchAll(`/medicalfiles/${ref}/satisfactionquestions`);
export const createSatisfactionAnswers = (ref, values) =>
  request(`/medicalfiles/${ref}/satisfactionanswers`, 'POST', values);
export const fetchSatisfactionAnswers = (ref) =>
  fetchAll(`/medicalfiles/${ref}/satisfactionanswers`);

// CONVERSATIONS
export const fetchAllConversations = (ref) => fetchAll(`/medicalfiles/${ref}/conversations`);
export const fetchAllMessages = (conversationId) =>
  fetchAll(`/conversations/${conversationId}/messages`);

// RATINGS
export const createRatings = (ref, values) =>
  request(`/medicalfiles/${ref}/ratings`, 'POST', values);

// WORKFLOW USER
export const requestWorkflowUser = (userId) => request(`/users/${userId}/workflow`);

// SEARCH
export const fetchSearchUser = (values, page = 1) =>
  request(`/users/search?page=${page}&${queryString.stringify(cleanObject(values))}`, 'GET');
export const fetchSearchMedicalFiles = (values, page = 1) =>
  request(`/medicalfiles/search?page=${page}&${queryString.stringify(cleanObject(values))}`, 'GET');

// COMMENTS
export const fetchComments = (ref) => fetchAll(`/medicalfiles/${ref}/comments`);
export const createComment = (ref, values) =>
  request(`/medicalfiles/${ref}/comments`, 'POST', values);

// FILES
export const fetchAllTranslations = (ref, fileType) =>
  request(`/medicalfiles/${ref}/files/all/${fileType}`);

// IMPERSONATE
export const loginAsPatient = (userId) => request(`/impersonate/${userId}`, 'POST');
export const logoutAsPatient = () => request(`/impersonate/leave`, 'POST');

// PROFILE
export const fetchProfile = (userId) => request(`/users/${userId}/profile`);
export const createProfile = (userId, values) =>
  request(`/users/${userId}/profile`, 'POST', {
    ...values,
    contact_type: 1,
    birthday: moment(values.birthday).format(moment.HTML5_FMT.DATE),
  });
export const updateProfile = (userId, values) =>
  request(`/users/${userId}/profile`, 'POST', {
    ...values,
    contact_type: 1,
    birthday: moment(values.birthday).format(moment.HTML5_FMT.DATE),
  });
