/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Switch } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

function CheckboxConsent({ label, name, required = true }) {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      rules={[
        {
          validator: (_, checked) => (checked ? Promise.resolve() : Promise.reject()),
          required,
          message: t('FRONT_FORM_MESSAGE_REQUIRED'),
        },
      ]}
    >
      <Checkbox style={{ whiteSpace: 'pre-wrap' }}>
        {required && <span style={{ color: '#ff4d4f', fontSize: 19 }}>* &nbsp;</span>}
        {label}
      </Checkbox>
    </Form.Item>
  );
}

export default CheckboxConsent;
