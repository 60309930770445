const languages = [
  { label: 'FRONT_LANGUAGE_FR_LABEL', code: 'FRONT_LANGUAGE_FR_CODE', value: 'fr' },
  { label: 'FRONT_LANGUAGE_EN_LABEL', code: 'FRONT_LANGUAGE_EN_CODE', value: 'en' },
  { label: 'FRONT_LANGUAGE_ES_LABEL', code: 'FRONT_LANGUAGE_ES_CODE', value: 'es' },
  { label: 'FRONT_LANGUAGE_AR_LABEL', code: 'FRONT_LANGUAGE_AR_CODE', value: 'ar' },
  { label: 'FRONT_LANGUAGE_GR_LABEL', code: 'FRONT_LANGUAGE_GR_CODE', value: 'gr' },
  { label: 'FRONT_LANGUAGE_I_LABEL', code: 'FRONT_LANGUAGE_I_CODE', value: 'i' },
  { label: 'FRONT_LANGUAGE_P_LABEL', code: 'FRONT_LANGUAGE_P_CODE', value: 'p' },
];

export default languages;
