import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TravelForm from '../../../components/MedicalFile/TravelForm';
import { usePatient } from '../../../redux/medicalFile/selectors';

function Travel({ medicalFile }) {
  const { t } = useTranslation();
  const [patient, isFetching] = usePatient();
  if (isFetching) return <Skeleton />;
  return <TravelForm medicalFile={medicalFile} />;
}

Travel.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Travel;
