import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRolesCreate } from '../../redux/metadata/selectors';

function SelectRoles() {
  const { t } = useTranslation();
  const rolesCreate = useRolesCreate();

  return (
    <Form.Item
      label={t('FRONT_USER_ROLE')}
      name="roles"
      rules={[
        {
          required: true,
          message: `${t('FRONT_USER_ROLE')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
        },
      ]}
    >
      <Select
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {rolesCreate.map((m) => (
          <Select.Option key={`role-${m.id}`} value={m.id}>
            {m.alias}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectRoles;
