import { Button, Descriptions, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { usePatient } from '../../redux/medicalFile/selectors';
import Flex from '../../components/Flex';
import useHasPermission from '../../hooks/useHasPermission';
import PatientInfo from '../../components/infos/PatientInfos';

function Patient() {
  const { t } = useTranslation();
  const history = useHistory();
  const [patient, isFetching] = usePatient();
  const hasPermission = useHasPermission();

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PatientInfo patient={patient} />
      {hasPermission('updateAny:profile') && (
        <Flex style={{ justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            onClick={() => history.push(`/profile/${patient.id}/edit`)}
          />
        </Flex>
      )}
    </>
  );
}

export default Patient;
