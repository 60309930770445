import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, message, Space } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import DownloadFileTraduction from '../components/DownloadFileTraduction';
import FormErrors from '../components/form/FormErrors';
import SelectLanguages from '../components/form/SelectLanguages';
import TableCustom from '../components/Table';
import acceptFileType from '../services/AcceptFileType';
import { fetchFileTranslations } from '../services/api';
import languages from '../services/languages';
import request from '../services/request';

function FileTraduction() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [err, setErr] = useState(null);
  const [[state, isFetching], setState] = useState([[], true]);

  const index = location.state?.index;
  const reference = location.state?.reference;

  const fetchData = useCallback(
    (page) =>
      fetchFileTranslations(reference, index, page)
        .then((s) => setState([s, false]))
        .catch(() => setState([[], false])),
    [index, reference]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: 'Language',
      dataIndex: 'lang',
      render: (v) => t(languages.find((l) => v === l.value)?.label),
    },
    { title: 'Size (kb)', dataIndex: 'size' },
    {
      title: t('updated_at'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              history.push('/viewer', {
                length: value.size,
                url: `/translations/${value.index}`,
              })
            }
            disabled={!acceptFileType(value)}
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>
          <DownloadFileTraduction fileIndex={index} tradIndex={value.index} />
        </Space>
      ),
    },
  ];

  if (!index) return null;

  return (
    <Form
      layout="vertical"
      onFinish={async (values) => {
        setErr(null);
        try {
          const file = values.file.fileList[0];
          const formData = new FormData();
          formData.append('file', file.originFileObj);
          formData.append('lang', values.language);
          await request(
            `/medicalfiles/${reference}/files/${location.state.index}/translations`,
            'POST',
            formData,
            true
          );
          await fetchData();
          message.success(t('FRONT_NOTIFICATION_UPLOAD_SUCCESS'));
        } catch (error) {
          message.error('FRONT_NOTIFICATION_UPLOAD_FAILED');
          setErr(error);
        }
      }}
    >
      <SelectLanguages />

      <Form.Item
        name="file"
        rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
      >
        <Dragger beforeUpload={() => false} multiple>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
      </Form.Item>

      <FormErrors err={err} />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('FRONT_FORM_SAVE')}
        </Button>
      </Form.Item>

      <TableCustom state={state} columns={columns} isFetching={isFetching} fetchData={fetchData} />
    </Form>
  );
}

export default FileTraduction;
