/* eslint-disable react/prop-types */
import { Descriptions } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function FormInfos({ questions, answers, type = 'health' }) {
  const { t } = useTranslation();
  return (
    <Descriptions
      className="form__descriptions"
      bordered
      column={1}
      style={{ marginBottom: 24 }}
      labelStyle={{ backgroundColor: 'white' }}
    >
      {(questions.groupBy || questions).map((q) => {
        const answer = { ...answers.find((a) => a[`${type}_question_id`] === q.id) };
        if (!answer) return null;
        if (q.type === 'boolean')
          // eslint-disable-next-line no-nested-ternary
          answer.answer = answer.answer
            ? answer.answer === '0'
              ? t('FRONT_COMMON_FALSE')
              : t('FRONT_COMMON_TRUE')
            : '';
        return (
          <Descriptions.Item key={q.id} label={q.question}>
            {answer.answer}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
}

export default FormInfos;
