/* eslint-disable react/prop-types */
import { Form, Button, Input, InputNumber, Select, message, DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchStep } from '../../redux/step/actions';
import request from '../../services/request';
import FormErrors from '../form/FormErrors';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';

function TravelDataForm({ medicalFile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [[isUpdating, updateErr], setIsUpdating] = useState([false, null]);

  const updateTravelData = async (values) => {
    setIsUpdating([true, null]);
    return request(`/medicalfiles/${medicalFile.reference}/traveldata`, 'POST', values)
      .then(() => {
        setIsUpdating([false, null]);
        dispatch(fetchStep(medicalFile.reference));
        dispatch(fetchMedicalFileData(medicalFile.reference));
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      })
      .catch((err) => {
        message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
        setIsUpdating([false, err]);
      });
  };

  return (
    <Form
      name="medical_file_traveldata"
      layout="vertical"
      initialValues={{ currency: 'euro', ...medicalFile.traveldata }}
      onFinish={updateTravelData}
      scrollToFirstError
    >
      <Form.Item label={t('FRONT_MEDICALE_FILE_AMOUNT')} required>
        <Input.Group compact>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}` }]}
            noStyle
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="currency"
            rules={[{ required: true, message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}` }]}
            noStyle
          >
            <Select>
              <Select.Option value="euro">€</Select.Option>
              <Select.Option value="dollar">$</Select.Option>
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item
        label={t('FRONT_MEDICALE_FILE_HOSPITAL')}
        name="hospital"
        rules={[{ required: true, message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}` }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('FRONT_MEDICALE_FILE_START')}
        name="start"
        getValueFromEvent={(v) => (v ? moment(v) : null)}
        rules={[
          {
            required: true,
            message: `${t('FRONT_MEDICALE_FILE_START')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label={t('FRONT_MEDICALE_FILE_EXIT')}
        name="exit"
        getValueFromEvent={(v) => (v ? moment(v) : null)}
        rules={[
          {
            required: true,
            message: `${t('FRONT_MEDICALE_FILE_EXIT')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label={t('FRONT_MEDICALE_FILE_END')}
        name="end"
        getValueFromEvent={(v) => (v ? moment(v) : null)}
      >
        <DatePicker />
      </Form.Item>

      <FormErrors err={updateErr} />

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isUpdating}>
          {t('FRONT_FORM_SEND')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default TravelDataForm;
