/* eslint-disable react/prop-types */
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Radio, Select, Upload, Modal, Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHasPermission from '../../hooks/useHasPermission';
import { useDocuments } from '../../redux/documents/selectors';
import { useMedicalFileFileTypes } from '../../redux/medicalFile/selectors';
import { useWorkflowUser } from '../../redux/workflowUser/selectors';
import FormErrors from '../form/FormErrors';

const styles = {
  macos: {
    backgroundImage: 'url(/compress_macos.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    display: 'block',
    position: 'relative',
    minHeight: 250,
    width: '75%',
    maxWidth: 520,
  },
  windows: {
    backgroundImage: 'url(/compress_windows.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    display: 'block',
    position: 'relative',
    minHeight: 250,
    width: '75%',
    maxWidth: 520,
  },
};

const { Dragger } = Upload;

// function for window dimensions https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// hook using the function above
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function DocumentCreate({ onSave, types = [], isUserDocuments, zipTutorial = false }) {
  const { t } = useTranslation();
  const ref = useRef();
  const [isPostal, setIsPostal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isZipTutorialOpen, setIsZipTutorialOpen] = useState(false);
  const medicalFileTypes = useMedicalFileFileTypes('medicals');
  const fileTypes = useMemo(
    () =>
      (types.length > 0 ? types : medicalFileTypes).sort((a, b) => (a.alias > b.alias ? 1 : -1)),
    [types, medicalFileTypes]
  );
  const [, , err] = useDocuments();
  const hasPermission = useHasPermission();
  const { height, width } = useWindowDimensions();

  const handleFinish = async (values) => {
    setIsUploading(true);
    await onSave({ ...values });
    if (ref.current) ref.current.resetFields();
    setIsUploading(false);
  };

  const showZipTutorial = () => {
    setIsZipTutorialOpen(true);
  };

  const hideZipTutorial = () => {
    setIsZipTutorialOpen(false);
  };

  useEffect(() => {
    if (err) {
      message.error(`${t('FRONT_NOTIFICATION_UPLOAD_FAILED')}`);
    }
  }, [t, err]);
  return (
    <>
      <Title level={5} style={{ marginTop: 10 }}>
        {t('FRONT_DOCUMENT_UPLOAD')}
      </Title>
      <Form
        ref={ref}
        layout="vertical"
        onFinish={(values) => handleFinish(values)}
        initialValues={{ postal: false }}
      >
        {!isUserDocuments && (
          <Form.Item name="postal" label={t('FRONT_DOCUMENT_SELECT_WAY')}>
            <Radio.Group onChange={(e) => setIsPostal(e.target.value)}>
              <Radio value={0}>{t('FRONT_DOCUMENT_WAY_UPLOAD')}</Radio>
              <Radio value={1}>{t('FRONT_DOCUMENT_WAY_POSTAL')}</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          name="file_type_id"
          label={t('FRONT_DOCUMENT_SELECT_TYPE')}
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Select
            placeholder="Select"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {fileTypes
              .filter((f) => hasPermission(`create FileType ${f.name}`))
              .map((f) => (
                <Select.Option key={f.id} value={f.id}>
                  {f.alias}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {!isUserDocuments && (
          <Form.Item
            name="name"
            label={t('FRONT_DOCUMENT_NAME')}
            rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
          >
            <Input />
          </Form.Item>
        )}

        {isPostal === 1 && (
          <>
            <Form.Item name="postal_type" label={t('FRONT_DOCUMENT_POSTAL_TYPE')}>
              <Input />
            </Form.Item>
            <Form.Item name="postal_number" label={t('FRONT_DOCUMENT_POSTAL_NUMBER')}>
              <Input />
            </Form.Item>
          </>
        )}

        {!isPostal && (
          <Form.Item
            name="file"
            rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
          >
            <Dragger beforeUpload={() => false} multiple>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('FRONT_DOCUMENT_FILE_UPLOAD')}</p>
            </Dragger>
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isUploading}>
            {t('FRONT_FORM_SAVE')}
          </Button>
          {!isPostal && zipTutorial && (
            <Button
              style={{ marginLeft: 10 }}
              icon={<QuestionCircleOutlined />}
              onClick={showZipTutorial}
            >
              {t('FRONT_MEDICAL_ARCHIVE_HELP_BUTTON')}
            </Button>
          )}
        </Form.Item>
      </Form>
      {zipTutorial && (
        <Modal
          title={t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_TITLE')}
          width={width * 0.9}
          footer={null}
          visible={isZipTutorialOpen}
          onOk={hideZipTutorial}
          onCancel={hideZipTutorial}
        >
          <Row>
            <Col span={24}>
              <p>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_INTRO')}</p>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={10}>
              <Title level={5}>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_SUBTITLE_WINDOWS')}</Title>
              <div style={styles.windows} />
              <p>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_TEXT_WINDOWS')}</p>
            </Col>
            <Col span={10}>
              <Title level={5}>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_SUBTITLE_MACOS')}</Title>
              <div style={styles.macos} /> <p>{t('FRONT_MEDICAL_ARCHIVE_TUTORIAL_TEXT_MACOS')}</p>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
}

export default DocumentCreate;
