import { Button, Form, message, Select, Switch } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useRoles, useUser } from '../../redux/user/selectors';
import request from '../../services/request';
import FormErrors from '../../components/form/FormErrors';
import SelectPathology from '../../components/form/SelectPathology';
import MedicalFileTypeRadio from '../../components/form/SelectMedicalFileType';
import T4T from '../../components/T4T';
import { useMedicaleFileType } from '../../redux/metadata/selectors';
import useHasPermission from '../../hooks/useHasPermission';
import { fetchAllPatients, fetchAllUsers } from '../../services/api';
import { isPatient } from '../../services/rights';
import CheckboxConsent from '../../components/form/CheckboxConsent';

function MedicalFileCreate() {
  const { t } = useTranslation();
  const history = useHistory();
  const medicaleFileTypes = useMedicaleFileType();
  const [state, setState] = useState({ isFetching: false, err: null, type: null });
  const [patients, setPatients] = useState([]);
  const [user] = useUser();
  const roles = useRoles();
  const [form] = Form.useForm();
  const hasPermission = useHasPermission();

  const fetchUsers = useCallback(() => {
    fetchAllPatients(user)
      .then((s) => setPatients(s))
      .catch(() => setPatients([]));
  }, [user]);

  useEffect(() => {
    if (!isPatient(roles)) fetchUsers();
  }, [fetchUsers, roles]);

  const create = async (values) => {
    try {
      setState({ isFetching: true });
      const { data } = await request('/medicalfiles', 'POST', {
        ...values,
        recoverystay: state.type === 1 ? values.recoverystay || false : undefined,
        visa: state.type === 1 ? values.visa || false : undefined,
      });
      history.push(`/medicals/${data.reference}`);
    } catch (err) {
      setState({ isFetching: false, err, type: state.type });
    }
  };
  const handleChange = (val) => {
    form.setFieldsValue({ type: val });
    setState((s) => ({ ...s, type: val }));
  };

  const resetType = () => {
    setState((s) => ({ ...s, type: null }));
  };
  if (isPatient(roles) && !user.profile) {
    message.info(t('FRONT_NOTIFICATION_COMPLETE_PROFILE'));
    return <Redirect to="/profile" />;
  }
  return (
    <div>
      <Title>
        {state.type !== null ? medicaleFileTypes[state.type] : t('FRONT_MEDICAL_FILE_TYPE_CHOICE')}
      </Title>
      <Form form={form} layout="vertical" onFinish={create}>
        <Form.Item name="type" shouldUpdate hidden={state.type !== null}>
          <MedicalFileTypeRadio onChange={handleChange} />
        </Form.Item>
        {state.type !== null && (
          <>
            <Button type="link" onClick={resetType} icon={<ArrowLeftOutlined />}>
              {t('FRONT_MEDICAL_FILES_CHANGE_TYPE')}
            </Button>
            <p style={{ marginBottom: 0 }}>
              <span style={{ color: '#ff4d4f' }}>*</span> {t('FRONT_MEDICAL_FILE_REQUIRED_FIELD')}
            </p>
            {hasPermission('create medicalfiles as') && (
              <Form.Item label={t('FRONT_MEDICAL_FILE_SELECT_PATIENT')} name="user_id" required>
                <Select
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {patients.map((m) => (
                    <Select.Option key={m.id} value={m.id}>
                      {`${m.firstname} ${m.lastname} (${m.email})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <SelectPathology />
          </>
        )}

        {state.type === 1 && <T4T />}

        {state.type !== null && (
          <CheckboxConsent
            name="data_protection_policy "
            label={
              <Trans i18nKey="FRONT_MEDICAL_FILE_DATA_PROTECTION_POLICY">
                By checking this box, you confirm that you have read the
                <Link to="/data-protection-policy" target="_blank">
                  Information Notice on the processing of your medical file
                </Link>
                and consent to all the processing of health data described in this notice, executed
                by ELSAN SAS, the facilities and health professionals in the ELSAN network
                consulted, as well as by the service providers that may respond to your potential
                needs.
                <br /> If you are submitting this file on behalf of a patient, you confirm that you
                have been delegated to create the file on the Health in France platform in the name
                and on behalf of the delegator. You also confirm that you have informed the
                delegating party and obtained his/her consent to the processing of personal data
                described above, that you have explained to him/her the rights that he/she may
                exercise over his/her data and, more generally, that you have complied with all the
                provisions set out in this
                <Link to="/data-protection-policy" target="_blank">
                  Information Notice on the processing of your medical file
                </Link>
              </Trans>
            }
          />
        )}

        <FormErrors err={state.err} />

        {state.type !== null && (
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={state.isFetching}>
              {t('FRONT_FORM_SAVE')}
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

export default MedicalFileCreate;
