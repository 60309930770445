import { SET_METADATA, METADATA_IS_FETCHING } from './actions';

const metadata = (state = [null, false], action) => {
  switch (action.type) {
    case SET_METADATA:
      return [action.metadata, false];
    case METADATA_IS_FETCHING:
      return [null, true];
    default:
      return state;
  }
};

export default metadata;
