import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import HomeImage from '../assets/images/Home.jpg';

const { Title, Paragraph, Text, Link } = Typography;

function Home() {
  const { t } = useTranslation();
  return (
    <section className="home">
      <Row style={{ height: '100%' }} align="middle">
        <Col sm={24} md={14}>
          <Typography className="home__description">
            <Title style={{ fontWeight: 'normal' }}>
              <Trans i18nKey="FRONT_HOME_TITLE" />
            </Title>
            <Paragraph>
              <Trans i18nKey="FRONT_HOME_WELCOME" />
            </Paragraph>
            <Paragraph>
              <Trans i18nKey="FRONT_HOME_DESCRIPTION" />
            </Paragraph>
            <Paragraph>
              <Trans i18nKey="FRONT_HOME_REGISTRATION" />
            </Paragraph>
            <Paragraph strong>
              <Trans i18nKey="FRONT_HOME_TEAM" />
            </Paragraph>
          </Typography>
        </Col>
        <Col sm={24} md={10} className="home__image">
          <img src={HomeImage} alt="Landing" />
        </Col>
      </Row>
    </section>
  );
}

export default Home;
