import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, Tag, Form, Space, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import TableCustom from '../../components/Table';
import WorkflowUser from '../../components/Workflow/WorkflowUser';
import useHasPermission from '../../hooks/useHasPermission';
import { useMedicaleFileType, usePathology } from '../../redux/metadata/selectors';
import { useRoles } from '../../redux/user/selectors';
import { deleteMedicalfile, fetchMedicalFiles } from '../../services/api';
import { isPatient } from '../../services/rights';

const styles = { icon: { fontSize: 18, cursor: 'pointer' } };

const renderTag = (value) => {
  if (value === 0) return <Tag color="green">&nbsp;</Tag>;
  if (value === 1) return <Tag color="orange">&nbsp;</Tag>;
  return <Tag color="red">&nbsp;</Tag>;
};

function MedicalFilesList() {
  const history = useHistory();
  const { t } = useTranslation();
  const pathologies = usePathology();
  const type = useMedicaleFileType();
  const hasPermission = useHasPermission();
  const roles = useRoles();
  const [[state, isFetching], setState] = useState([null, true]);

  const fetchData = async (page, order) => {
    fetchMedicalFiles(page, order)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const removeMedicalfile = async (reference) => {
    try {
      setState(([c]) => [c, true]);
      await deleteMedicalfile(reference);
      await fetchData(state.meta.current_page);
      message.success(t('FRONT_NOTIFICATION_DELETE_SUCCESS'));
    } catch (err) {
      setState(([c]) => [c, false]);
      message.error(t('FRONT_NOTIFICATION_DELETE_FAILED'));
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: ['step', 'commitment'],
      width: 30,
      render: (i) => renderTag(i),
    },
    {
      title: t('FRONT_MEDICAL_FILE_REFERENCE'),
      dataIndex: 'reference',
      width: 150,
      render: (r) => <Link to={`/medicals/${r}`}>{r}</Link>,
      sorter: true,
    },
    {
      title: t('FRONT_MEDICAL_FILE_TYPE'),
      dataIndex: 'type',
      width: 250,
      render: (i) => type[i],
      sorter: true,
    },
    hasPermission('view medicalfiles pathology')
      ? {
          title: t('FRONT_MEDICAL_FILE_PATHOLOGY'),
          dataIndex: 'pathology',
          render: (i) => pathologies.find((p) => p.id === i).name,
          sorter: true,
        }
      : {},
    !isPatient(roles)
      ? {
          title: t('FRONT_USER_FIRST_NAME'),
          dataIndex: ['user', 'firstname'],
          width: 150,
          sorter: true,
        }
      : {},
    !isPatient(roles)
      ? {
          title: t('FRONT_USER_LAST_NAME'),
          dataIndex: ['user', 'lastname'],
          width: 150,
          sorter: true,
        }
      : {},
    {
      title: t('FRONT_MEDICAL_FILE_STATUS'),
      dataIndex: ['step', 'description'],
      width: 150,
      sorter: true,
    },
    {
      title: t('FRONT_MEDICAL_FILE_ACTION_USER'),
      dataIndex: ['step', 'action_user'],
    },
    {
      title: t('FRONT_COMMON_CREATED_AT'),
      dataIndex: 'updated_at',
      render: (r) => new Date(r).toLocaleString(),
      sorter: true,
    },
    {
      key: 'action',
      width: 100,
      render: (_, item) => (
        <Space size="large">
          <Link to={`/medicals/${item.reference}`}>
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {hasPermission('delete health files') && (
            <DeleteOutlined
              onClick={() => removeMedicalfile(item.reference)}
              style={{ ...styles.icon, color: 'red' }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Title>{t('FRONT_MEDICAL_FILE_LABELS')}</Title>
      <Form
        layout="inline"
        style={{ marginBottom: 10 }}
        onFinish={(v) => history.push('/medicals/search', v)}
      >
        <Form.Item label={t('FRONT_MEDICAL_FILE_REFERENCE')} name="reference">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('FRONT_FORM_SEARCH')}
          </Button>
        </Form.Item>
      </Form>
      <TableCustom
        state={state}
        columns={columns}
        isFetching={isFetching}
        fetchData={fetchData}
        xScroll={1500}
      />
    </>
  );
}

export default MedicalFilesList;
