import { requestWorkflowUser } from '../../services/api';

export const SET_WORKFLOW_USER = 'SET_WORKFLOW_USER';
export const WORKFLOW_USER_IS_FETCHING = 'WORKFLOW_USER_IS_FETCHING';

const setWorkflowUser = (workflow) => ({ type: SET_WORKFLOW_USER, workflow });
const isFetching = () => ({ type: WORKFLOW_USER_IS_FETCHING });

export const fetchWorlflowUser = (userId) => async (dispatch, getState) => {
  dispatch(isFetching());
  try {
    const { data } = await requestWorkflowUser(userId);
    dispatch(setWorkflowUser(data));
  } catch {
    dispatch(setWorkflowUser(null));
  }
};
