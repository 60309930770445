import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Space, Divider, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { url } from '../../services/request';

const styles = {
  form: { minWidth: 300, width: '60%', margin: 'auto' },
  divider: { margin: '16px 0' },
  background: {
    backgroundImage: 'url(/bg-connexion.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    minHeight: 200,
    width: '100%',
  },
  leftside: {
    padding: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [currentState, setState] = useState({ isFetching: false, err: null });
  const [user] = useSelector((state) => state.user);

  const prevLocation = location.state?.prevLocation;

  if (user) return <Redirect to="/dashboard" />;

  const signIn = () => {
    window.location.href = `${url}/auth0/login${prevLocation ? `?redirectTo=${prevLocation}` : ''}`;
  };

  return (
    <Row style={{ minHeight: 'calc(100vh - 64px)' }} justify="center" align="stretch">
      <Col sm={24} md={12} style={styles.leftside}>
        <div>
          <Title style={{ textAlign: 'center' }}>{t('FRONT_LOGIN_TITLE')}</Title>
          <Form style={styles.form} layout="vertical" name="login" onFinish={() => signIn()}>
            <Button
              style={styles.button}
              type="primary"
              htmlType="submit"
              loading={currentState.isFetching}
              block
            >
              {t('FRONT_LOGIN_LABEL')}
            </Button>
            <Divider style={styles.divider}>{t('FRONT_LOGIN_REGISTER_QUESTION')}</Divider>

            <Button
              style={styles.button}
              type="primary"
              block
              onClick={() => history.push('/register')}
            >
              {t('FRONT_LOGIN_REGISTER')}
            </Button>
          </Form>
        </div>
      </Col>
      <Col sm={24} md={12} style={styles.background} />
    </Row>
  );
}

export default Login;
