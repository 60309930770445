import { fetchAllUserMedicalFiles } from '../../services/api';
import request from '../../services/request';

export const SET_MEDICAL_FILES = 'SET_MEDICAL_FILES';
export const MEDICAL_FILES_IS_FETCHING = 'MEDICAL_FILES_IS_FETCHING';

const setMedicalFiles = (medicalFiles) => ({ type: SET_MEDICAL_FILES, medicalFiles });
const isFetching = () => ({ type: MEDICAL_FILES_IS_FETCHING });

export const fetchMedicalFiles = (userId) => async (dispatch) => {
  dispatch(isFetching());
  const { data } = await fetchAllUserMedicalFiles(userId, 1);
  dispatch(setMedicalFiles(data));
};
