import {
  FETCH_DOCUMENTS_IS_FETCHING,
  FETCH_DOCUMENTS_STOP_FETCHING,
  SET_DOCUMENTS,
} from './actions';

const documents = (state = [[], true, null], action) => {
  switch (action.type) {
    case SET_DOCUMENTS:
      return [action.documents, false, null];
    case FETCH_DOCUMENTS_IS_FETCHING:
      return [state[0], true, null];
    case FETCH_DOCUMENTS_STOP_FETCHING:
      return [state[0], false, action.err];
    default:
      return state;
  }
};

export default documents;
