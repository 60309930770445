/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
// import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import CornerstoneViewport from 'react-cornerstone-viewport';
import Hammer from 'hammerjs';

const CornerstoneViewer = ({ m, index }) => {
  const [ready, setReady] = useState(false);
  // state-based configuration tools object
  const [images, setImages] = useState([]);
  const [tools, setTools] = useState([
    // Mouse
    {
      name: 'Wwwc',
      mode: 'active',
      modeOptions: { mouseButtonMask: 1 },
    },
    {
      name: 'Zoom',
      mode: 'active',
      modeOptions: { mouseButtonMask: 2 },
    },
    {
      name: 'Pan',
      mode: 'active',
      modeOptions: { mouseButtonMask: 4 },
    },
    // Scroll
    { name: 'StackScrollMouseWheel', mode: 'active' },
    // Touch
    { name: 'PanMultiTouch', mode: 'active' },
    { name: 'ZoomTouchPinch', mode: 'active' },
    { name: 'StackScrollMultiTouch', mode: 'active' },
  ]);

  useEffect(() => {
    // converts base 64 file data to a file object
    const loadFile = (file, mime) => {
      const binaryString = window.atob(file);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const fileObject = new File(
        [new Blob([bytes.buffer])],
        `file.${mime === 'application/zip' ? 'zip' : 'dcm'}`,
        {
          type: mime,
        }
      );
      // load file with dedicated Cornerstone loader
      const image = cornerstoneWADOImageLoader.wadouri.fileManager.add(fileObject);
      images.push(image);
    };

    const initCornerstone = () => {
      // Cornerstone Tools
      cornerstoneTools.external.cornerstone = cornerstone;
      cornerstoneTools.external.Hammer = Hammer;
      cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
      cornerstoneTools.init();

      // Image Loader
      /* 
      // 2023-04: DICOMImageLoader still on beta branch, better stay on legacy solutions
      cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
      cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;
      cornerstoneDICOMImageLoader.webWorkerManager.initialize({
        maxWebWorkers: navigator.hardwareConcurrency || 1,
        startWebWorkersOnDemand: true,
      });
      cornerstoneDICOMImageLoader.configure({
        beforeSend: (xhr) => {
          console.log('beforesend called');
          xhr.withCredentials = true;
        },
      }) */

      cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
      cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
      cornerstoneWADOImageLoader.webWorkerManager.initialize({
        maxWebWorkers: navigator.hardwareConcurrency || 1,
        startWebWorkersOnDemand: true,
        taskConfiguration: {
          decodeTask: {
            initializeCodecsOnStartup: false,
            usePDFJS: false,
            strict: false,
          },
        },
      });
      cornerstoneWADOImageLoader.configure({
        beforeSend: (xhr) => {
          xhr.withCredentials = true;
        },
      });

      cornerstoneWebImageLoader.external.cornerstone = cornerstone;
      cornerstoneWebImageLoader.configure({
        beforeSend: (xhr) => {
          xhr.withCredentials = true;
        },
      });
    };

    if (m) {
      const { file, mime } = m;
      if (mime === 'application/zip') {
        setImages(m.refs);
        initCornerstone();
      } else {
        loadFile(file, mime);
        initCornerstone();
      }
      setReady(true);
    }
  }, [m, images]);

  return (
    <>
      {ready ? (
        <CornerstoneViewport
          tools={tools}
          imageIds={images}
          style={{ minWidth: '100%', height: '512px', flex: '1' }}
        />
      ) : (
        <span>Loading viewer...</span>
      )}
    </>
  );
};

// remove proptypes while testing integration
CornerstoneViewer.propTypes = {
  m: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};

export default CornerstoneViewer;
