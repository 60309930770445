const regions = [
  'Pas de préférence',
  'Auvergne-Rhône-Alpes',
  'Bourgogne-Franche-Comté',
  'Bretagne',
  'Centre Val-de-Loire',
  'Grand-Est',
  'Hauts-de-France',
  'Ile-de-France',
  'Normandie',
  'Nouvelle-Aquitaine',
  'Occitanie',
  'PACA',
  'Pays de Loire',
  'Maroc',
];

export default regions;
