import { Button, Checkbox, Form, Modal, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import routesTemplates from '../../routes';
import { fetchUser, setProfile } from '../../redux/user/actions';
import Loading from './Loading';
import { useUser } from '../../redux/user/selectors';
import { useMetadata } from '../../redux/metadata/selectors';

import i18n from '../../services/i18n';
import CheckboxConsent from '../form/CheckboxConsent';
import request from '../../services/request';

function Routes() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [user, uFetching] = useUser();
  const [consentsLoading, setConsentsLoading] = useState(false);
  const [, mFetching] = useMetadata();

  useEffect(() => {
    dispatch(fetchUser(location.pathname, history));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history]);

  const sendConsents = async (values) => {
    setConsentsLoading(true);
    await request(`/users/${user.id}/consents`, 'POST', values);
    dispatch(fetchUser(null, history));
    setConsentsLoading(false);
  };

  const isFetching = uFetching || mFetching;
  if (isFetching) return <Loading />;
  return (
    <Switch>
      {routesTemplates.map((routesTemplate) => {
        const { routes, template: Layout, type } = routesTemplate;
        return routes.map((route) => (
          <Route
            exact={route.exact}
            path={route.path}
            key={route.path}
            render={(r) => (
              <Layout>
                <Modal
                  title={t('FRONT_CONSENTS_TITLE')}
                  visible={user && !user.consents && type === 'private'}
                  closable={false}
                  footer={[
                    <Button
                      type="primary"
                      htmlType="submit"
                      form="consentsForm"
                      loading={consentsLoading}
                    >
                      {t('FRONT_COMMON_SEND')}
                    </Button>,
                  ]}
                >
                  <Form onFinish={sendConsents} name="consentsForm">
                    <CheckboxConsent
                      name="tos_accepted"
                      label={
                        <>
                          <span>{t('FRONT_PROFILE_CGU_CONFIRM_1')}&nbsp;</span>
                          <Link to="/cgu" target="_blank">
                            {t('FRONT_PROFILE_CGU_CONFIRM_2')}
                          </Link>
                        </>
                      }
                    />
                    <CheckboxConsent
                      name="privacy_accepted"
                      label={
                        <>
                          <span>{t('FRONT_PROFILE_PRIVACY_POLICY_CONFIRM_1')}&nbsp;</span>
                          <Link to="/policy" target="_blank">
                            {t('FRONT_PROFILE_PRIVACY_POLICY_CONFIRM_2')}
                          </Link>
                        </>
                      }
                    />
                  </Form>
                </Modal>

                {route.component}
              </Layout>
            )}
          />
        ));
      })}
      <Route>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={() => history.replace('/')}>
              Back Home
            </Button>
          }
        />
      </Route>
    </Switch>
  );
}

export default Routes;
