import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePathology } from '../../redux/metadata/selectors';

function SelectPathology() {
  const { t } = useTranslation();
  const pathologies = usePathology();

  return (
    <Form.Item
      label={t('FRONT_MEDICAL_FILE_PATHOLOGY')}
      name="pathology"
      rules={[
        {
          required: true,
          message: `${t('FRONT_MEDICAL_FILE_PATHOLOGY')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
        },
      ]}
    >
      <Select placeholder={t('FRONT_COMMON_SELECT')}>
        {pathologies.map((p) => (
          <Select.Option key={p.id} value={p.id}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectPathology;
