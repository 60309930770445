import { SET_PERMISSIONS } from './actions';

const permissions = (state = [], action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return action.permissions;
    default:
      return state;
  }
};

export default permissions;
