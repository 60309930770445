import { Button } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { useMedicals } from '../../redux/medicals/selectors';
import { useRoles } from '../../redux/user/selectors';
import { useWorkflowUser } from '../../redux/workflowUser/selectors';
import { useStep } from '../../redux/step/selectors';
import { hasRole, isPatient } from '../../services/rights';
import Flex from '../Flex';

function WorkflowUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [workflow, isFetching] = useWorkflowUser();
  const [currentStep, setCurrentStep] = useState(null);
  const roles = useRoles();
  const [step] = useStep();
  const [medicalFiles] = useMedicals();
  const [medicalFile] = useMedicalFile();

  useEffect(() => {
    if (medicalFiles.length > 0) {
      setCurrentStep(medicalFiles[0].step);
    }
  }, [medicalFiles]);

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step]);

  if (isFetching) return <SkeletonButton />;

  const steps = () => {
    if (workflow.step === 'fill_profile')
      return (
        <Button type="primary" size="large" onClick={() => history.push('/profile')}>
          {workflow.description}
        </Button>
      );

    if (workflow.step === 'create_medical_file')
      if (medicalFiles.length > 0 && !medicalFiles[0].closed && currentStep) {
        return (
          <Button
            type="primary"
            size="large"
            onClick={() =>
              history.push(
                `/medicals/${medicalFile ? medicalFile.reference : medicalFiles[0].reference}`
              )
            }
          >
            {hasRole(roles, currentStep.roles) ? currentStep.name : currentStep.description}
          </Button>
        );
      } else {
        return (
          <Button type="primary" size="large" onClick={() => history.push('/medicals/add')}>
            {workflow.description}
          </Button>
        );
      }

    return null;
  };

  return <Flex style={{ marginBottom: 10 }}>{steps()}</Flex>;
}

export default WorkflowUser;
