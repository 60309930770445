import { fetchAllFileTypes, fetchAllRoles } from '../../services/api';
import request from '../../services/request';

export const SET_METADATA = 'SET_METADATA';
export const METADATA_IS_FETCHING = 'METADATA_IS_FETCHING';

export const metadataIsFetching = () => ({ type: METADATA_IS_FETCHING });
const setMetadata = (metadata) => ({ type: SET_METADATA, metadata });

export const fetchMetadata = () => async (dispatch) => {
  dispatch(metadataIsFetching());
  const [metadata, fileTypes, roles] = await Promise.all([
    request(`/metadata/all`, 'GET'),
    fetchAllFileTypes(),
    fetchAllRoles(),
  ]);
  return dispatch(setMetadata({ ...metadata, fileTypes, roles }));
};
