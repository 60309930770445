/* eslint-disable react/prop-types */
import { Form, Image, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAssistance } from '../../redux/metadata/selectors';

function SelectAssistance({ required = false }) {
  const { t, i18n } = useTranslation();

  const assistances = useAssistance();

  return (
    <Form.Item
      label={
        <div>
          {t('FRONT_MEDICAL_FILE_ASSISTANCE')} &nbsp;&nbsp;&nbsp;
          <Image
            width={50}
            style={{ display: 'inline-block' }}
            src={`/Assistance_Plans_${i18n.language}.jpg`}
          />
        </div>
      }
      name="assistance"
      rules={[
        {
          required,
          message: '',
        },
      ]}
    >
      <Typography.Paragraph>
        <blockquote>{t('FRONT_MEDICAL_FILE_ASSISTANCE_INFO')}</blockquote>
      </Typography.Paragraph>
      <Form.Item
        name="assistance"
        rules={[
          {
            required,
            message: `${t('FRONT_MEDICAL_FILE_ASSISTANCE')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <Select placeholder={t('FRONT_COMMON_SELECT')}>
          {assistances.map((p, i) => (
            <Select.Option key={p} value={i}>
              {i === 0 ? p : `${p} - ${t('FRONT_MEDICAL_FILE_ASSISTANCE_ACCEPT')}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form.Item>
  );
}

export default SelectAssistance;
