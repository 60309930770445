import { Button, Collapse, Form, Input, message, Typography, Image } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormErrors from '../../components/form/FormErrors';
import SelectLanguages from '../../components/form/SelectLanguages';
import { useUser } from '../../redux/user/selectors';
import { updateUser, updateUserPassword } from '../../services/api';

function UserAccount() {
  const { t, i18n } = useTranslation();
  const formRef = useRef();
  const [state, setState] = useState({ isFetching: false, err: null });
  const [user] = useUser();

  const update = async (values) => {
    setState({ isFetching: true, err: null });
    try {
      await updateUser(user.id, values);
      setState({ isFetching: false, err: null });
      message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  return (
    <div>
      <Title>{t('FRONT_SIDEBAR_ACCOUNT')}</Title>
      <Form
        layout="vertical"
        name="account"
        onFinish={update}
        initialValues={user}
        scrollToFirstError
        ref={formRef}
      >
        <Form.Item
          label={t('FRONT_USER_EMAIL')}
          name="email"
          rules={[{ type: 'email', required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <SelectLanguages required />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={state.isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>

      {state.err && <FormErrors err={state.err} />}
    </div>
  );
}

export default UserAccount;
