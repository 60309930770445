import { Button, Skeleton, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import FormInfos from '../../../components/infos/FormInfos';
import DoctorForm from '../../../components/MedicalFile/DoctorForm';
import useT4TStepActive from '../../../hooks/useT4TStepActive';
import { useWorkflowUser } from '../../../redux/workflowUser/selectors';
import { fetchDoctorAnswers, fetchDoctorQuestions } from '../../../services/api';
import { groupBy } from '../../../services/helper';

function Doctor({ medicalFile }) {
  const { t } = useTranslation();
  const { afterStep } = useT4TStepActive();

  const [agree, setAgree] = useState(null);
  const [loading, setLoading] = useState(true);
  const [[missingQuestions, completeQuestions], setQuestions] = useState([[], []]);
  const [answers, setAnswers] = useState([]);

  const fetchAnswersQuestions = useCallback(async () => {
    try {
      const questions = await fetchDoctorQuestions(medicalFile.reference);
      let aq = questions.filter((a) => a.workflow === 'MISSING');
      let cq = questions.filter((a) => a.workflow === 'COMPLETE');
      aq = groupBy(aq, 'category');
      cq = groupBy(cq, 'category');
      setQuestions([aq, cq]);
      const a = await fetchDoctorAnswers(medicalFile.reference);
      setAnswers(a);
      if (a.length <= 6) setAgree(null);
    } catch (err) {
      console.log('error: ', err);
    }
    return null;
  }, [medicalFile.reference]);

  useEffect(() => {
    fetchAnswersQuestions();
  }, [fetchAnswersQuestions]);

  return (
    <>
      {answers.length > 0 &&
        missingQuestions.map((cat) => (
          <div key={cat.id}>
            <Title level={4} className="medicalfiles__category">
              {cat.category}
            </Title>
            <FormInfos questions={cat} answers={answers} type="doctor" />
          </div>
        ))}
      {!afterStep('doctor.stepvalidatemedicalfile') && (
        <Flex style={{ marginBottom: 20 }}>
          <Space>
            {agree !== true && (
              <Button type="primary" onClick={() => setAgree(true)}>
                {t('FRONT_MEDICAL_FILE_STEP_AGREE')}
              </Button>
            )}
            {answers.length === 0 && agree !== false && (
              <Button type="primary" onClick={() => setAgree(false)}>
                {t('FRONT_MEDICAL_FILE_STEP_NOT_AGREE')}
              </Button>
            )}
          </Space>
        </Flex>
      )}
      {(afterStep('doctor.stepvalidatemedicalfile') || agree !== null) && (
        <DoctorForm
          medicalFile={medicalFile}
          agree={agree}
          goBack={() => setAgree(answers > 0 ? false : null)}
          questions={agree === false ? missingQuestions : completeQuestions}
          answers={answers}
          fetchAnswersQuestions={fetchAnswersQuestions}
        />
      )}
    </>
  );
}

Doctor.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Doctor;
