/* eslint-disable no-param-reassign */
import { Button, Input, Form, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import SelectCivility from '../../components/form/SelectCivility';
import SelectCompanies from '../../components/form/SelectCompanies';
import SelectCountries from '../../components/form/SelectCountries';
import SelectLanguages from '../../components/form/SelectLanguages';
import SelectRoles from '../../components/form/SelectRoles';
import SelectTimeZones from '../../components/form/SelectTimeZones';
import useHasPermission from '../../hooks/useHasPermission';
import { useUser } from '../../redux/user/selectors';
import { createUser, updateUser } from '../../services/api';

function CreateUser() {
  const { t } = useTranslation();
  const location = useLocation();
  const formRef = useRef();
  const [state, setState] = useState({ isFetching: false, err: null });
  const hasPermission = useHasPermission();
  const [userCo] = useUser();

  const user = location.state?.user;

  if (user) {
    user.company_id = user.company.id;
    user.roles = user.roles.map((r) => r.id || r);
  }

  const create = async (values) => {
    setState({ isFetching: true, err: null });
    try {
      if (values.company_id === null) values.company_id = userCo.company.id;
      if (user) {
        await updateUser(user.id, values);
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      } else {
        await createUser(values);
        message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
        if (formRef.current) formRef.current.resetFields();
      }

      setState({ isFetching: false, err: null });
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  return (
    <div>
      <Title>{t('FRONT_USER_CREATE')}</Title>
      <Form
        ref={formRef}
        layout="vertical"
        name="profile"
        onFinish={create}
        initialValues={user}
        scrollToFirstError
      >
        {hasPermission('assign companies') && <SelectCompanies />}

        <SelectRoles />

        <SelectCivility />

        <Form.Item
          label={t('FRONT_USER_FIRST_NAME')}
          name="firstname"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_LAST_NAME')}
          name="lastname"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_EMAIL_FOR_LOGIN')}
          name="email"
          rules={[{ type: 'email', required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_EMAIL_FOR_NOTIFICATION')}
          name="notification_email"
          rules={[{ type: 'email', message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <SelectLanguages />

        <SelectCountries keyValue="code" />

        <SelectTimeZones />

        <FormErrors err={state.err} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={state.isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CreateUser;
