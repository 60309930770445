import React from 'react';
import Profile from '../pages/User/Profile';
import UserAccount from '../pages/User/Account';
import MyDocuments from '../pages/User/Documents';
import ListUsers from '../pages/Users/List';
import CreateUser from '../pages/Users/Create';
import MedicalFiles from '../pages/Users/MedicalFiles';
import EntitiesList from '../pages/Entities/List';
import CreateEntity from '../pages/Entities/Create';
import MedicalFile from '../pages/MedicalFile';
import MedicalFilesList from '../pages/MedicalFiles/List';
import MedicalFileCreate from '../pages/MedicalFiles/Create';
import PatientsList from '../pages/Patients/List';
import PatientCreate from '../pages/Patients/Create';
import FileTraduction from '../pages/FileTraduction';
import Viewer from '../pages/Viewer/Viewer';
import Dashboard from '../pages/User/Dashboard';
import Logout from '../pages/Authentication/Logout';
import MedicalFileSearch from '../pages/MedicalFiles/Search';
import EmailCheck from '../pages/Authentication/EmailCheck';
import UserProfile from '../pages/UserProfile';

const routes = [
  {
    component: <Dashboard />,
    path: '/dashboard',
  },
  {
    component: <Profile />,
    path: '/profile',
    exact: true,
  },
  {
    component: <UserProfile />,
    path: '/profile/:userId/edit',
  },
  {
    component: <UserAccount />,
    path: '/account',
  },
  {
    component: <MyDocuments />,
    path: '/documents',
  },
  {
    component: <Logout />,
    path: '/logout',
  },
  {
    component: <ListUsers />,
    path: '/users',
    exact: true,
  },
  {
    component: <CreateUser />,
    path: '/users/add',
  },
  {
    component: <MedicalFiles />,
    path: '/users/:userId/medicals',
  },
  {
    path: '/entities',
    component: <EntitiesList />,
    exact: true,
  },
  {
    component: <CreateEntity />,
    path: '/entities/add',
  },
  {
    component: <MedicalFilesList />,
    path: '/medicals',
    exact: true,
  },
  {
    component: <MedicalFileCreate />,
    path: '/medicals/add',
  },
  {
    component: <MedicalFileSearch />,
    path: '/medicals/search',
  },
  {
    component: <PatientsList />,
    path: '/patients',
    exact: true,
  },
  {
    component: <PatientCreate />,
    path: '/patients/add',
  },
  {
    component: <Viewer />,
    path: '/viewer',
  },
  {
    component: <MedicalFile />,
    path: '/medicals/:reference',
  },
  {
    component: <FileTraduction />,
    path: '/translation',
  },
  {
    component: <EmailCheck />,
    path: '/email/verify',
  },
];

export default routes;
