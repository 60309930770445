/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import request from '../services/request';

function DownloadFileTraduction({ fileIndex, tradIndex }) {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);

  const download = async () => {
    setIsFetching(true);
    try {
      const file = await request(`/translations/${tradIndex}`);
      const zip = new JSZip();
      zip.file('file', file.file, { base64: true });
      zip.generateAsync({ type: 'blob' }).then((content) => {
        FileSaver.saveAs(content, 'download.zip');
      });
      setIsFetching(false);
      message.success(t('FRONT_NOTIFICATION_SEND_SUCCESS'));
    } catch {
      setIsFetching(false);
      message.error(t('FRONT_NOTIFICATION_SEND_FAILED'));
    }
  };

  return (
    <Button type="primary" onClick={() => download()} loading={isFetching}>
      Télécharger
    </Button>
  );
}

export default DownloadFileTraduction;
