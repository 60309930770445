/* eslint-disable react/prop-types */
import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { set } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormErrors from '../../../components/form/FormErrors';
import TableCustom from '../../../components/Table';
import useHasPermission from '../../../hooks/useHasPermission';
import useStepActive from '../../../hooks/useSMOStepActive';
import { fetchStep, nextStep } from '../../../redux/step/actions';
import { useRoles } from '../../../redux/user/selectors';
import { fetchSMO } from '../../../services/api';
import request from '../../../services/request';
import { isExpertDoctor, isLocalDoctor } from '../../../services/rights';
import UpdateSMO from '../../UpdateSMO';

function SMO({ reference }) {
  const { t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [[state, isFetching], setState] = useState([{ data: [] }, true]);
  const [[isCreating, errors], setCreate] = useState([false, null]);
  const [onEdit, setOnEdit] = useState(null);
  const hasPermission = useHasPermission();
  const roles = useRoles();
  const { isStep, beforeStep } = useStepActive();

  const fetchData = useCallback(
    (page) =>
      fetchSMO(reference, page)
        .then((s) => setState([s, false]))
        .catch(() => setState([{ data: [] }, false])),
    [reference]
  );

  useEffect(() => {
    if (onEdit == null) fetchData();
  }, [fetchData, onEdit]);

  const create = async (values) => {
    setCreate([true, null]);
    try {
      const file = values.file.fileList[0];
      const formData = new FormData();
      formData.append('file', file.originFileObj);
      formData.append('name', values.name);
      await request(`/medicalfiles/${reference}/smo`, 'POST', formData, true);
      await dispatch(fetchStep(reference));
      await fetchData();
      if (ref.current) ref.current.resetFields();
      setCreate([false, null]);
    } catch (err) {
      setCreate([false, err]);
    }
  };

  const columns = [
    { title: t('FRONT_DOCUMENT_NAME'), dataIndex: 'name' },
    { title: t('FRONT_DOCUMENT_SIZE'), dataIndex: 'size', render: (v) => Math.floor(v / 1000) },

    {
      title: t('FRONT_COMMON_UPDATED_AT'),
      dataIndex: 'updated_at',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: t('FRONT_DOCUMENT_SMO_UPLOAD_BY_FIRST_NAME'),
      dataIndex: ['uploadedBy', 'firstname'],
    },
    {
      title: t('FRONT_DOCUMENT_SMO_UPLOAD_BY_LAST_NAME'),
      dataIndex: ['uploadedBy', 'lastname'],
    },
    {
      key: 'action',
      render: (value) => (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              history.push('/viewer', { url: `/medicalfiles/${reference}/smo/${value.index}` })
            }
          >
            {t('FRONT_DOCUMENT_SHOW')}
          </Button>

          {hasPermission('update smo files') && (
            <Button type="primary" onClick={() => setOnEdit(value.index)}>
              {t('FRONT_DOCUMENT_UPDATE')}
            </Button>
          )}

          {isLocalDoctor(roles) && (
            <Button
              type="primary"
              onClick={() =>
                history.push(`/medicals/${reference}/smo/${value.index}/translations/add`)
              }
            >
              {t('FRONT_DOCUMENT_TRADUCTIONS')}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  if (onEdit) {
    return <UpdateSMO reference={reference} smo={onEdit} onFinish={() => setOnEdit(null)} />;
  }

  return (
    <>
      {hasPermission('create smo files') && isExpertDoctor(roles) && state?.data.length === 0 && (
        <>
          <Form ref={ref} layout="vertical" onFinish={create}>
            <Form.Item
              name="name"
              label={t('FRONT_DOCUMENT_NAME')}
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="file"
              rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
            >
              <Dragger beforeUpload={() => false} multiple>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('FRONT_FORM_MESSAGE_REQUIRED')}</p>
              </Dragger>
            </Form.Item>

            <FormErrors err={errors} />

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isCreating}>
                {t('FRONT_FORM_SAVE')}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {Array.isArray(state?.data) ? (
        <TableCustom
          state={state}
          isFetching={isFetching}
          columns={columns}
          fetchData={fetchData}
        />
      ) : (
        <Table rowKey="id" dataSource={[state?.data]} columns={columns} loading={isFetching} />
      )}
    </>
  );
}

export default SMO;
