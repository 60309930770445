/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import request from '../../services/request';

export const useMedicalFile = () => useSelector((state) => state.medicalFile);
export const useMedicalFileFileTypes = (group = null) =>
  useSelector((state) => {
    const fts = state.medicalFile[0]?.fileTypes;
    if (!group) return fts;
    if (fts) return fts.filter((ft) => ft.group === group);
    return [];
  });
export const useMedicalFileType = () => useSelector((state) => state.medicalFile[0].type);
export const usePatient = () => {
  const [[profile, isFetching], setProfile] = useState([{}, true]);

  const patient = useSelector((state) => state.medicalFile[0].user);

  useEffect(() => {
    request(`/users/${patient.id}/profile`)
      .then((p) => setProfile([p, false]))
      .catch(() => setProfile([{}, false]));
  }, [patient.id]);

  return [{ ...patient, profile }, isFetching];
};
