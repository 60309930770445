import { Button, Input, Select, Form, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import SelectCivility from '../../components/form/SelectCivility';
import SelectCompanies from '../../components/form/SelectCompanies';
import SelectCountries from '../../components/form/SelectCountries';
import SelectLanguages from '../../components/form/SelectLanguages';
import SelectTimeZones from '../../components/form/SelectTimeZones';
import useQuery from '../../hooks/useQuery';
import useHasPermission from '../../hooks/useHasPermission';
import { useRoles as useAllRoles } from '../../redux/metadata/selectors';
import { useUser } from '../../redux/user/selectors';
import { createUser, updateUser } from '../../services/api';

function PatientCreate() {
  const { t } = useTranslation();
  const query = useQuery();
  const referral = query.get('referral');
  const location = useLocation();
  const formRef = useRef();
  const [state, setState] = useState({ isFetching: false, err: null });
  const [user] = useUser();
  const hasPermission = useHasPermission();
  const allRoles = useAllRoles();

  const patientRole = allRoles.find((r) => r.name === 'Patient');

  const patient = location.state?.patient;
  const create = async (values) => {
    setState({ isFetching: true, err: null });
    try {
      if (values.company_id === null) values.company_id = user.company.id;
      if (patient) {
        await updateUser(patient.id, values);
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      } else {
        await createUser({ ...values, roles: [patientRole?.id] });
        message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
        if (formRef.current) formRef.current.resetFields();
      }

      setState({ isFetching: false, err: null });
    } catch (err) {
      setState({ isFetching: false, err });
    }
  };

  return (
    <>
      <Title>{t('FRONT_PATIENT_CREATE')}</Title>
      <Form
        ref={formRef}
        layout="vertical"
        name="create-patient"
        onFinish={create}
        initialValues={patient}
        scrollToFirstError
      >
        {hasPermission('assign companies') && !referral && <SelectCompanies />}

        <SelectCivility />

        <Form.Item
          label={t('FRONT_USER_FIRST_NAME')}
          name="firstname"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_LAST_NAME')}
          name="lastname"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <SelectCountries keyValue="code" />

        <Form.Item label={t('FRONT_USER_EMAIL_FOR_LOGIN')} name="email">
          <Input />
        </Form.Item>

        <SelectLanguages />

        <SelectTimeZones />

        <FormErrors err={state.err} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={state.isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default PatientCreate;
