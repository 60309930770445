import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import Messages from '../../../components/Messages';
import request from '../../../services/request';
import { useRoles, useUser } from '../../../redux/user/selectors';
import Comments from '../../../components/MedicalFile/Comments';
import { isExpertDoctor } from '../../../services/rights';
import useHasPermission from '../../../hooks/useHasPermission';

function Contact({ medicalFile }) {
  const { t } = useTranslation();
  const { interlocutors } = medicalFile;
  const [user] = useUser();
  const roles = useRoles();
  const hasPermission = useHasPermission();
  const [[conversations, isFetching], setState] = useState([[], true]);

  const fetchConversations = useCallback(async () => {
    const { data } = await request(`/medicalfiles/${medicalFile.reference}/conversations`, 'GET');
    setState([data, false]);
  }, [medicalFile]);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  return (
    <>
      <Collapse accordion bordered={false}>
        {interlocutors.map((i) => {
          if (
            i.interlocutor.id === user.id ||
            i.type === 'Viewer' ||
            !hasPermission(`create:conversations:with:${i.interlocutor.roles[0].name}`)
          )
            return null;
          const conversation = conversations.find(
            (c) =>
              (c.sender.id === user.id && c.recipient.id === i.interlocutor.id) ||
              (c.sender.id === i.interlocutor.id && c.recipient.id === user.id)
          );
          return (
            <Collapse.Panel
              header={`${t('FRONT_MEDICAL_FILE_MESSAGES_WITH')} ${i.interlocutor.firstname} ${
                i.interlocutor.lastname
              } (${i.interlocutor.roles[0].alias})`}
              key={i.interlocutor.id}
            >
              <Messages
                medicalFile={medicalFile}
                conversation={conversation}
                interlocutor={i.interlocutor}
              />
            </Collapse.Panel>
          );
        })}
        {isExpertDoctor(roles) && (
          <Collapse.Panel header={t('FRONT_MEDICAL_FILE_COMMENTS_DOCTORS')}>
            <Comments url={`/medicalfiles/${medicalFile.reference}/doctorcomments`} />
          </Collapse.Panel>
        )}
      </Collapse>
    </>
  );
}

Contact.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Contact;
