import request from '../../services/request';
import { userIsFetching } from '../user/actions';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';

const setPermissions = (permissions) => ({ type: SET_PERMISSIONS, permissions });

export const fetchPermissions = (userId) => async (dispatch) => {
  dispatch(userIsFetching());
  const permissions = await request(`/users/${userId}/permissions`, 'GET');
  dispatch(setPermissions(permissions));
};
