import { Form, Button, message, Skeleton, Descriptions } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Flex from '../Flex';
import FormDynamic from '../form/FormDynamic';
import FormErrors from '../form/FormErrors';
import { fetchStep, nextStep } from '../../redux/step/actions';
import { createHealthAnswers, fetchHealthAnswers, fetchHealthQuestions } from '../../services/api';
import { groupBy } from '../../services/helper';
import FormInfos from '../infos/FormInfos';
import useStepActive from '../../hooks/useSMOStepActive';

const scrollOnTop = () => {
  const top = document.querySelector(`#header`);
  top.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

function HealthForm({ medicalFile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [onEdit, setOnEdit] = useState(true);
  const [[isSaving, saveErr], setIsSaving] = useState([false, null]);
  const [[answers, questions, isFetching], setState] = useState([[], [], true]);
  const { beforeStep } = useStepActive();

  const { reference } = medicalFile;

  const save = async (values) => {
    setIsSaving([true, null]);
    const arr = Object.entries(values)
      .filter(([, v]) => v)
      .map(([k, v]) => [k, v.toString()]);
    const a = arr.map(([key, answer]) => ({ health_question_id: Number(key), answer }));
    try {
      await createHealthAnswers(reference, {
        medical_file_id: medicalFile.id,
        answers: a,
      });
      if (answers.length === 0) {
        dispatch(fetchStep(reference));
      }
      const newAnswers = await fetchHealthAnswers(reference);
      setState([newAnswers, questions, false]);
      setIsSaving([false, null]);
      message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
      scrollOnTop();
    } catch (err) {
      setIsSaving([false, err]);
      message.error(t('FRONT_NOTIFICATION_SAVE_FAILED'));
    }
  };

  const fetchAnswersQuestions = useCallback(async () => {
    try {
      let q = await fetchHealthQuestions(reference);
      q = groupBy(q, 'category');
      const a = await fetchHealthAnswers(reference);
      setState([a, q, false]);
    } catch {
      setState([[], [], false]);
    }
  }, [reference]);

  useEffect(() => {
    fetchAnswersQuestions();
  }, [fetchAnswersQuestions]);

  useEffect(() => {
    if (answers.length > 0) {
      setOnEdit(false);
    }
  }, [answers]);

  if (isFetching) return <Skeleton />;

  return (
    <>
      {onEdit ? (
        <Form layout="vertical" name="health" onFinish={save} scrollToFirstError>
          {questions.map((cat) => (
            <div key={cat.id}>
              <Title level={4} className="medicalfiles__category">
                {cat.category}
              </Title>
              {cat.groupBy && <FormDynamic questions={cat.groupBy} responses={answers} />}
            </div>
          ))}

          <FormErrors err={saveErr} />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('FRONT_FORM_SAVE')}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        questions.map((cat) => (
          <div key={cat.id}>
            <Title level={4} className="medicalfiles__category">
              {cat.category}
            </Title>
            <FormInfos questions={cat} answers={answers} />
          </div>
        ))
      )}
      {answers.length > 0 && beforeStep('doctor.stepvalidatemedicalfile') && (
        <Flex style={{ justifyContent: 'flex-end' }}>
          <Button
            type={onEdit ? 'danger' : 'primary'}
            shape="round"
            icon={onEdit ? <ArrowLeftOutlined /> : <EditOutlined />}
            onClick={() => setOnEdit(!onEdit)}
          />
        </Flex>
      )}
    </>
  );
}

HealthForm.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HealthForm;
