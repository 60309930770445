import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DocumentCreate from '../../components/Documents/Create';
import UserDocumentsList from '../../components/UserDocumentsList';
import WorkflowUser from '../../components/Workflow/WorkflowUser';
import { createDocuments, fetchDocuments, removeDocument } from '../../redux/documents/actions';
import { useDocuments } from '../../redux/documents/selectors';
import { useMedicals } from '../../redux/medicals/selectors';
import { useFileTypes } from '../../redux/metadata/selectors';
import { useRoles, useUser } from '../../redux/user/selectors';
import { isPatient } from '../../services/rights';

function UserDocuments() {
  const dispatch = useDispatch();
  const [medicals] = useMedicals();
  const [documents, isFetching] = useDocuments();
  const [user] = useUser();
  const roles = useRoles();
  const idFileTypes = useFileTypes('id');
  const idDocuments = useMemo(() => {
    const ids = documents?.filter((d) => idFileTypes.map((f) => f.id).includes(d.file_type_id));
    return ids;
  }, [documents, idFileTypes]);

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch]);

  return (
    <>
      {medicals.length === 0 && isPatient(roles) && <WorkflowUser />}
      <DocumentCreate
        types={idFileTypes}
        onSave={async (values) => dispatch(createDocuments(null, user.id, values))}
        isUserDocuments
      />
      <UserDocumentsList documents={idDocuments} isFetching={isFetching} userId={user.id} />
    </>
  );
}

export default UserDocuments;
